import {
	EnvironmentTypesEnum,
	repositoryBranchNamespaces,
	repositoryIdNamespaces,
	repositoryNameSpaces,
	repositoryRelativePathNamespaces,
	sourceControlIntegrationIdNameSpaces,
	TFormikCommonProps,
	TOptions,
} from '../../helpers';
import { SelectGithubBranch } from '..';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useGetPaginatedGithubRepositories } from '../../../../services/githubApi';
import { FormikValues } from '../../../../../@xmcloud/hooks/context/CreateProjectContext';
import {
	InputFormControl,
	InputFormControlWithTransformationMessage,
	SelectFormControl,
} from '../../../../shared-components';
import { debounce } from 'lodash';
import {
	fourthStep,
	secondStep,
} from '../../../../../@xmcloud/core/messages/en';
import { Stack } from '@chakra-ui/react';

const { selectRepo, info2, nameYourRepo } = secondStep;
const { fieldLabel1, desc1 } = fourthStep;

export const GithubRepository: React.FC<
	{
		isSourceControlIntegrationLoading: boolean;
		nameSpace?: EnvironmentTypesEnum | 'default';
		accessToken: string;
		setValues: (values: any) => void | any;
		setFieldTouched: any;
		handleChange: any;
		showRepoCombobox?: boolean;
		setFieldValue: (field: string, value: any) => void;
		isByoc?: boolean;
	} & TFormikCommonProps
> = ({
	isSourceControlIntegrationLoading,
	nameSpace = 'default',
	accessToken,
	values,
	errors,
	touched,
	setValues,
	setFieldTouched,
	handleChange,
	showRepoCombobox = false,
	setFieldValue,
	isByoc = false,
}) => {
	const inputQuery = useRef('');

	const repositoryNamespace = repositoryNameSpaces[nameSpace];
	const repositoryIdNamespace = repositoryIdNamespaces[nameSpace];
	const repositoryBranchNamespace = repositoryBranchNamespaces[nameSpace];
	const sourceControlIntegrationIdNameSpace =
		sourceControlIntegrationIdNameSpaces[nameSpace];
	const repositoryRelativePathNameSpace =
		repositoryRelativePathNamespaces[nameSpace];

	const sourceControlIntegrationId =
		values[sourceControlIntegrationIdNameSpace];

	const {
		data,
		isLoading: isLoadingGithubRepositories,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useGetPaginatedGithubRepositories(
		accessToken,
		sourceControlIntegrationId,
		!!accessToken,
		onSuccess,
	);

	const repositoryOptions: TOptions = useMemo(
		() =>
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
				id: r.id,
			})) || [],
		[data],
	);

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasNextPage || !lastPage.length) return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const handleFetchNextPage = useCallback(() => {
		if (hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isFetching]);

	const onInputChange = useCallback(
		(e: any) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasNextPage ||
				!repositoryOptions?.length ||
				isFetching
			)
				return;

			const hasRepo = repositoryOptions.some((r: any) =>
				r.label.includes(e),
			);

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[
			handleFetchNextPage,
			hasNextPage,
			inputQuery,
			isFetching,
			repositoryOptions,
		],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	const onRepositoryChange = (e: any) => {
		setValues((prev: FormikValues) => ({
			...prev,
			[repositoryNamespace]: e.label || '',
			[repositoryIdNamespace]: e.id || '',
			[repositoryBranchNamespace]: '',
		}));
	};

	const isLoading =
		isSourceControlIntegrationLoading || isLoadingGithubRepositories;

	if (showRepoCombobox)
		return (
			<Stack gap="7">
				<SelectFormControl
					{...{
						error: errors[repositoryNamespace],
						isInvalid: Boolean(
							errors[repositoryNamespace] &&
								touched[repositoryNamespace],
						),
						isLoading: isLoading || isFetching,
						options: repositoryOptions,
						onChange: onRepositoryChange,
						label: selectRepo,
						name: repositoryNamespace,
						currentValue: values[repositoryNamespace],
						onFocus: () =>
							setFieldTouched(repositoryNamespace, true),
						onInputChange: debouncedChangeHandler,
						onMenuScrollToBottom: () => {
							handleFetchNextPage();
						},
						isRequired: true,
						pt: 0,
					}}
				/>
				<SelectGithubBranch
					{...{
						token: accessToken,
						nameSpace,
						values,
						errors,
						touched,
						setFieldValue,
					}}
				/>
				{isByoc && (
					<InputFormControl
						{...{
							isInvalid: Boolean(
								errors[repositoryRelativePathNameSpace] &&
									touched[repositoryRelativePathNameSpace],
							),
							label: fieldLabel1,
							name: repositoryRelativePathNameSpace,
							value: values[repositoryRelativePathNameSpace],
							pt: 0,
							onChange: handleChange,
							error: errors[repositoryRelativePathNameSpace],
							onFocus: () =>
								setFieldTouched(
									repositoryRelativePathNameSpace,
									true,
								),
							isRequired: true,
							helperText: desc1,
						}}
					/>
				)}
			</Stack>
		);

	return (
		<InputFormControlWithTransformationMessage
			{...{
				isInvalid: Boolean(
					errors[repositoryNamespace] && touched[repositoryNamespace],
				),
				label: nameYourRepo,
				name: repositoryNamespace,
				value: values[repositoryNamespace],
				pt: 0,
				maxW: 'md',
				onChange: handleChange,
				error: errors[repositoryNamespace],
				onFocus: () => setFieldTouched(repositoryNamespace, true),
				setFieldValue,
				isRequired: true,
				helperText: info2,
			}}
		/>
	);
};
