import { FC, useState } from 'react';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { CreateProjectProvider } from '../../../@xmcloud/hooks';
import { MainModal } from './components';
import { Outlet } from 'react-router-dom';
import { AlertDialogConfirmationModal, Text } from '../../shared-components';
import { firstStep } from '../../../@xmcloud/core/messages/en';
import { useCreateProjectV3 } from './old-components/useCreateProjectV3';

const { projects: projectsGoto } = goTo;
const { dialogTitle, dialogDesc, leave } = firstStep;

export const MainWizardCreateProject: FC = () => {
	const [isOpen, setIsOpen] = useState(true);
	const [openAlertDialog, setOpenAlertDialog] = useState(false);
	const { enableCreateProjectV3 } = useCreateProjectV3();

	function onClose() {
		enableCreateProjectV3 ? setOpenAlertDialog(true) : onConfirm();
	}
	function onConfirm() {
		setIsOpen(false);
		handlePush(projectsGoto);
	}

	return (
		<CreateProjectProvider>
			<MainModal {...{ isOpen, onClose }}>
				<Outlet />
			</MainModal>
			<AlertDialogConfirmationModal
				isOpen={openAlertDialog}
				title={dialogTitle}
				onClose={() => setOpenAlertDialog(false)}
				dataTestId="confirmation-modal-to-leave-cw"
				onConfirm={onConfirm}
				leftButtonText={leave}
			>
				<Text text={dialogDesc} />
			</AlertDialogConfirmationModal>
		</CreateProjectProvider>
	);
};
