import { ESourceControl, SourceControlType } from '../../../../@xmcloud/types';
import {
	FormikValues,
	useCreateProject,
} from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import {
	CONNECTION_NAME,
	DEPLOY_ON_COMMIT,
	IS_BYOC,
	PROVIDER,
	REPOSITORY,
	REPO_BRANCH,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../helpers';
import {
	commonCw,
	secondStep,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { HeadingLg, HeadingMd, XMCSButton } from '../../../shared-components';
import { FooterBtnGroups } from '../components';
import { Card, Stack, Tooltip } from '@chakra-ui/react';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';

const { connectGitHub, connectAzure, sourceControl, selectedtxt } = secondStep;
const { connect: connecttxt } = commonCw;
const { githubStatus, adoStatus, bothUnavailable, errMsg1 } =
	systemHealthStatus;

const { GitHub, ADO } = ESourceControl;

const SourceControls: React.FC = () => {
	const {
		setFieldValue,
		values,
		setValues,
		setTouched,
		prev,
		next,
		isADOProvider,
		isGithubProvider,
	} = useCreateProject();
	const { isADOHealthy, isGithubHealthy, isLoading, isError } =
		useComponentHealthy({});

	function resetConnectionFields() {
		setValues((prev: FormikValues) => ({
			...prev,
			[SOURCE_CONTROL_INTEGRATION_ID]: '',
			[REPOSITORY]: '',
			[CONNECTION_NAME]: '',
			[REPO_BRANCH]: '',
			[PROVIDER]: '',
			[DEPLOY_ON_COMMIT]: false,
			[IS_BYOC]: false,
		}));
		setTouched({});
	}

	function navigateProviderPage(provider: SourceControlType) {
		if (values.provider && values.provider !== provider) {
			resetConnectionFields();
		}
		setFieldValue(PROVIDER, provider);
	}

	const provider = values.provider;

	const isBothProviderUnhealthy = !isGithubHealthy && !isADOHealthy;

	return (
		<Stack gap={5}>
			<HeadingLg text={sourceControl} />
			<SelectCard
				{...{
					title: connectGitHub,
					isSelected: isGithubProvider,
					onClick: () => navigateProviderPage(GitHub),
					isDisabled: !isGithubHealthy,
					tooltiptxt: githubStatus,
				}}
				key={connectGitHub}
			/>
			<SelectCard
				{...{
					title: connectAzure,
					isSelected: isADOProvider,
					onClick: () => navigateProviderPage(ADO),
					isDisabled: !isADOHealthy,
					tooltiptxt: adoStatus,
				}}
				key={connectAzure}
			/>
			<FooterBtnGroups
				onPreviousClick={prev}
				showRightIcon={false}
				onNextClick={next}
				py={1}
				isDisabled={!provider || isBothProviderUnhealthy}
				isLoading={isLoading}
				tooltiptxt={
					isError
						? errMsg1
						: isBothProviderUnhealthy
							? bothUnavailable
							: ''
				}
			/>
		</Stack>
	);
};

export default SourceControls;

const SelectCard: React.FC<{
	title: string;
	isSelected?: boolean;
	onClick?: () => void;
	isDisabled?: boolean;
	tooltiptxt?: string;
}> = ({ title, isDisabled, isSelected = false, tooltiptxt = '', onClick }) => (
	<Card variant="elevated" maxW="3xl" py="5" px="6" data-testid="card-box">
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
		>
			<HeadingMd text={title} />
			<Tooltip
				label={tooltiptxt}
				shouldWrapChildren
				isDisabled={!isDisabled}
			>
				<XMCSButton
					text={isSelected ? selectedtxt : connecttxt}
					variant={isSelected ? 'outline' : 'solid'}
					onClick={onClick}
					isDisabled={isDisabled}
				/>
			</Tooltip>
		</Stack>
	</Card>
);
