import { Stack, Divider } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
	app,
	environment as envLabels,
	environments as environmentsLabels,
} from '../../../../@xmcloud/core/messages/en';
import {
	HeadingMd,
	Icon,
	IconText,
	LoadingSkeleton,
	TextBox,
	Link,
} from '../../../shared-components';
import { mdiSourceBranch, mdiOpenInNew } from '@mdi/js';
import { t } from '@transifex/native';
import EdgeToken from '../components/EdgeToken';
import {
	useEnvironmentDetails,
	useProjectDetails,
} from '../../../../@xmcloud/hooks';
import { ApiTokenComponent } from '../components/EdgeAPIToken';
import EditingSecret from '../components/EditingSecret';
import { useFeature } from '../../../feature-flag/features';
import { ContextID } from '../components/ContextID';
import {
	getReadableDate,
	getReadableTime,
} from '../../../../@xmcloud/utils/dateUtils';

const {
	hostNamesAndUrls: hostNamesAndUrlstxt,
	hostName: hostNametxt,
	dashboard: dashboardtxt,
	authoringGraphQlEndpoint: authoringGraphQlEndpointtxt,
	authoringGraphQlEndpointTooltip,
	launchIDE: launchIDEtxt,
} = envLabels;
const { autoDeploy: autoDeploytxt } = environmentsLabels;
const { details: detailstxt } = app;

export const EnvironmentDetails: React.FC = () => {
	const { environmentId, projectId, ...rest } = useParams<{
		environmentId: string;
		projectId: string;
		'envType-environments': string;
	}>();
	const envType = rest['envType-environments']?.split('-')[0];
	const createProjectV2 = useFeature('CreateProjectV2');

	const { environment, isLoading: isEnvironmentLoading } =
		useEnvironmentDetails(environmentId!);

	const { environment: linkedCmEnvironment } = useEnvironmentDetails(
		environment?.editingHostEnvironmentDetails?.cmEnvironmentId!,
		envType === 'eh' &&
			!!environment?.editingHostEnvironmentDetails?.cmEnvironmentId,
	);

	const { project: projectDetails, isLoading: projectIsLoading } =
		useProjectDetails(projectId!);

	const isLoading = projectIsLoading || isEnvironmentLoading;
	const isEHProject = envType === 'eh';
	const host = environment?.host || '';

	if (isLoading) return <LoadingSkeleton height={36} amount={12} mt={10} />;

	const authoringGraphQlEndpoint = `https://${host}/sitecore/api/authoring/graphql/playground`;
	const dashboardUrl = `https://${host}/sitecore`;

	const getContextIdProps = () => ({
		previewContextId: environment?.previewContextId!,
		liveContextId: environment?.liveContextId!,
		environmentId: environmentId!,
	});

	const CmView = () => (
		<Stack spacing={5} mt={5}>
			<HeadingMd text={detailstxt} />
			<ContextID {...getContextIdProps()} />
			<TextBox title="Environment ID" subTitle={environment?.id!} />
			<TextBox title="Type" subTitle={environment?.tenantType} />
			<TextBox
				title="Selected region"
				subTitle={projectDetails?.data?.region}
			/>
			<TextBox
				title="Base image version"
				subTitle={`${environment?.sitecoreMajorVersion}.${environment?.sitecoreMinorVersion}`}
			/>
			<Divider />
		</Stack>
	);

	const EhView = () => (
		<Stack spacing={5} mt={5}>
			<HeadingMd text={detailstxt} />
			<TextBox
				title="Linked CM environment"
				subTitle={linkedCmEnvironment?.name!}
				isLink={true}
				href={`/projects/${projectId}/cm-environments/${linkedCmEnvironment?.id!}/details`}
			/>
			<TextBox
				title="Linked CM environment ID"
				subTitle={linkedCmEnvironment?.id!}
			/>
			<Divider />
		</Stack>
	);

	const RepositoryView = () => (
		<Stack spacing={5} mt={5}>
			<HeadingMd text="Repository" />
			<TextBox
				title="Repository account"
				subTitle={
					environment?.repositoryDetails?.sourceControlIntegrationName
				}
			/>
			<TextBox
				title="Repository name"
				subTitle={environment?.repositoryDetails?.repository}
			/>
			{isEHProject && (
				<TextBox
					title="Repository linked on"
					subTitle={`${getReadableDate(
						environment?.repositoryDetails?.repositoryLinkedAt,
					)} at ${getReadableTime(environment?.repositoryDetails?.repositoryLinkedAt)}`}
				/>
			)}
			<IconText
				text={autoDeploytxt}
				IconName={mdiSourceBranch}
				iconSize={5}
				iconColor="neutral"
			/>
			{envType !== 'eh' && <Divider />}
		</Stack>
	);

	const HostNamesView = () => (
		<Stack spacing={5} mt={5}>
			<HeadingMd text={hostNamesAndUrlstxt} />
			<TextBox title={hostNametxt} subTitle={host} key={hostNametxt} />
			<TextBox
				title={dashboardtxt}
				subTitle={dashboardUrl}
				key={dashboardtxt}
				isLink={true}
				iconSize={14}
				linkIcon={mdiOpenInNew}
				subTitleFontWeight="semibold"
				href={dashboardUrl}
			/>
			<TextBox
				subTitle=""
				title={authoringGraphQlEndpointtxt}
				key={authoringGraphQlEndpointtxt}
				linkIcon={mdiOpenInNew}
				tooltip={authoringGraphQlEndpointTooltip}
			>
				<Link
					isExternal
					href={authoringGraphQlEndpoint}
					boxShadow="none !important"
					fontWeight="semibold"
					text={null}
					to=""
				>
					{t(launchIDEtxt)}
					<Icon path={mdiOpenInNew} ml={1} />
				</Link>
			</TextBox>
			<ApiTokenComponent host={host} />
			<EdgeToken />
			{createProjectV2 && isEHProject && <EditingSecret />}
		</Stack>
	);

	return (
		<>
			{envType === 'cm' && <CmView />}
			{envType === 'eh' && <EhView />}
			<RepositoryView />
			{envType !== 'eh' && <HostNamesView />}
		</>
	);
};
