import { createFeatureFlags } from '@sitecore/featureflags';

export const {
	Feature,
	FlagsProvider,
	useFeature,
	useFeatures,
	withFeature,
	behindFeature,
} = createFeatureFlags({
	SplitEhAndCm: false,
	ContentTransfer: false,
	DeveloperSettingForLowerJss: false,
	CreateProjectV2: false,
	RegenerateContextId: false,
	CreateProjectV3: false,
});

export type FeatureFlags = ReturnType<typeof useFeatures>;
export type FeatureName = keyof FeatureFlags;
