import { Card, CardBody, HStack, Stack } from '@chakra-ui/react';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { HeadingMd, Text } from '../../../shared-components';
import {
	editProject,
	fifthStep,
	thirdStep,
} from '../../../../@xmcloud/core/messages/en';
import { ESourceControl } from '../../../../@xmcloud/types';

const { ADO } = ESourceControl;

const { cardTitle } = thirdStep;
const { repoName, sourceControl, githubAccount, adoAccount } = fifthStep;
const { adoOrg, adoProject } = editProject;

export const RepositoryDetails: React.FC = () => {
	const {
		values: {
			cmProvider,
			cmSourceControlIntegrationName,
			cmRepository,
			cmAdoOrganization,
			cmAdoProjectName,
		},
	} = useCreateProject();

	const isAdoProvider = cmProvider === ADO;

	const items = [
		{ name: sourceControl, value: cmProvider },
		{
			name: isAdoProvider ? adoAccount : githubAccount,
			value: cmSourceControlIntegrationName,
		},
		{ name: repoName, value: cmRepository },
		...(isAdoProvider
			? [
					{ name: adoOrg, value: cmAdoOrganization },
					{ name: adoProject, value: cmAdoProjectName },
				]
			: []),
	];
	return (
		<Stack gap="4">
			<HeadingMd text="Repository" />
			<Card variant="filled" size="lg">
				<CardBody>
					<Stack gap="4">
						<Text text={cardTitle} color="chakra-subtle-text" />
						{items.map(({ name, value }) => (
							<HStack key={name}>
								<Text text={name} w="20%" color="neutral-fg" />
								<Text text={value} w="80%" color="black" />
							</HStack>
						))}
					</Stack>
				</CardBody>
			</Card>
		</Stack>
	);
};
