import { useQueryClient } from 'react-query';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	REPOSITORY,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../helpers';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { QueryResponse } from '../../../../@xmcloud/types';
import { TGetADORepositories } from '../../../models/externalApi';
import config from '../../../config/config';
import { secondStep } from '../../../../@xmcloud/core/messages/en';

const { alreadyRepoExist, requiredAccount } = secondStep;

export const useADOValidation = (
	values: FormikValues,
	setFieldTouched: any,
	setFieldError: any,
	next: any,
) => {
	const queryClient = useQueryClient();
	function handleValidation() {
		const {
			sourceControlIntegrationId,
			adoOrganization,
			adoProjectName,
			repository,
		} = values;
		const { queryKey } = config.sourceControl.get_ado_repositories;

		const adoRepositoriesQueryData = queryClient.getQueryData(
			queryKey(adoOrganization, adoProjectName),
		) as QueryResponse<TGetADORepositories>;

		if (!sourceControlIntegrationId) {
			setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true);
			setFieldError(SOURCE_CONTROL_INTEGRATION_ID, requiredAccount);
			return;
		}
		if (!adoOrganization) {
			setFieldTouched(ADO_ORGANIZATION, true);
			return;
		}
		if (!adoProjectName) {
			setFieldTouched(ADO_PROJECT_NAME, true);
			return;
		}
		if (!repository) {
			setFieldTouched(REPOSITORY, true);
			return;
		}
		if (repository && !values.isByoc) {
			const hasSameRepoName = adoRepositoriesQueryData?.data.value.find(
				(i) => i.name === repository,
			);

			if (hasSameRepoName) {
				setFieldError(REPOSITORY, alreadyRepoExist);
				return;
			}
		}
		next();
	}

	return { handleValidation };
};
