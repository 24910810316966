import { Button, ButtonGroup, HStack, Stack } from '@chakra-ui/react';
import { HeadingMd, SelectFormControl, Text } from '../../../shared-components';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import {
	CREATE_EDITING_HOST,
	IS_BYOC,
	LINK_ENV_TO_DEFAULT_BRANCH,
	resetCmConnectionLevelFields,
	resetEhConnectionLevelFields,
	TEMPLATE,
	TEMPLATE_OWNER,
} from '../helpers';
import { firstStep, secondStep } from '../../../../@xmcloud/core/messages/en';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { useEffect } from 'react';

const { usetemplate, useOwnCode } = secondStep;
const { repoSetup, template: templatetxt, templateTitle } = firstStep;

export const SetupTemplate: React.FC<{
	templates: {
		label: string;
		value: string;
		owner: string;
	}[];
	isLoading: boolean;
}> = ({ templates, isLoading }) => {
	const { setValues, setFieldTouched, errors, values, isByoc } =
		useCreateProject();

	useEffect(() => {
		if (templates.length > 0) {
			setValues((prev) => ({
				...prev,
				[TEMPLATE]: templates[0].value,
				[TEMPLATE_OWNER]: templates[0].owner,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templates.length]);

	const handleUseTemplateClick = () => {
		setValues((prev) => ({
			...prev,
			[IS_BYOC]: false,
			[LINK_ENV_TO_DEFAULT_BRANCH]: true,
			[CREATE_EDITING_HOST]: false,
			...resetCmConnectionLevelFields,
			...resetEhConnectionLevelFields,
		}));
	};

	const handleUseOwnCodeClick = () => {
		setValues((prev) => ({
			...prev,
			[IS_BYOC]: true,
			[LINK_ENV_TO_DEFAULT_BRANCH]: false,
			[CREATE_EDITING_HOST]: true,
			...resetCmConnectionLevelFields,
			...resetEhConnectionLevelFields,
		}));
	};

	return (
		<Stack gap="4">
			<HeadingMd text={repoSetup} />
			<Text text={templateTitle} variant="small" maxW="md" />
			<HStack>
				<ButtonGroup
					variant="toggle"
					size="sm"
					layerStyle="toggleGroup"
				>
					<Button
						onClick={handleUseTemplateClick}
						data-testid="use-xmcloud-template-btn"
						isActive={!isByoc}
					>
						{usetemplate}
					</Button>
					<Button
						onClick={handleUseOwnCodeClick}
						data-testid="use-own-code-btn"
						isActive={isByoc}
					>
						{useOwnCode}
					</Button>
				</ButtonGroup>
			</HStack>
			{!isByoc && (
				<SelectFormControl
					{...{
						isInvalid: Boolean(errors.template),
						isLoading: isLoading,
						onChange: (e: any) => {
							setValues((prev: FormikValues) => ({
								...prev,
								[TEMPLATE]: e.value,
							}));
						},
						label: templatetxt,
						name: TEMPLATE,
						currentValue: values.template,
						error: errors.template,
						pt: 2,
						onFocus: () => setFieldTouched(TEMPLATE, true),
						isRequired: true,
						options: templates,
					}}
				/>
			)}
		</Stack>
	);
};
