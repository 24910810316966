import { useQueryClient } from 'react-query';
import config from '../../app/config/config';
import { IGetProjectResponseV2 } from '../../app/models/projectModel';
import { useGetProject } from '../../app/services/projects';
import { QueryResponse } from '../types';
const {
	projects: { project: projectConfig },
} = config;

export function useProjectDetails(projectId: string) {
	const queryClient = useQueryClient();

	const project = queryClient.getQueryData(
		projectConfig.queryKey(projectId),
	) as QueryResponse<IGetProjectResponseV2>;

	const {
		data: projectData,
		isLoading,
		isFetching,
	} = useGetProject({
		id: projectId,
		enabled: !Boolean(project?.data),
	});
	const projectName = project?.data.name || projectData?.data.name;
	const projectType = 'combined';

	return { projectName, project, projectType, isLoading, isFetching };
}
