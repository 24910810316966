import { Card, CardBody, Stack } from '@chakra-ui/react';
import { fifthStep } from '../../../../@xmcloud/core/messages/en';
import { FormPlaceHolder, Text, XMCSButton } from '../../../shared-components';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../../@xmcloud/utils/helpers';

const { edit } = fifthStep;
const { createProject } = goTo;

export const ReviewFinishCard: React.FC<{
	items: { title: string; subTitle: string; titleValue?: string }[];
	stepNumber: number;
	cardTitle?: string;
}> = ({ items = [], stepNumber = 1, cardTitle = '' }) => {
	const { setActiveStep } = useCreateProject();

	return (
		<Card variant="filled">
			<CardBody>
				<Stack direction="row" justifyContent="space-between">
					<Stack direction="column" gap="2" w="85% !important">
						{Boolean(cardTitle) && (
							<Text
								fontSize="lg"
								fontWeight="semibold"
								color="chakra-subtle-text"
								text={cardTitle}
							/>
						)}
						{items.map((i) => (
							<FormPlaceHolder
								{...{
									title: i.title,
									description: i.subTitle,
									titleValue: i?.titleValue || '',
									spacing: 0,
									pt: 0,
								}}
								key={i.title}
							/>
						))}
					</Stack>
					<XMCSButton
						size="sm"
						variant="ghost"
						colorScheme="primary"
						onClick={() => {
							setActiveStep(stepNumber);
							handlePush(createProject(stepNumber));
						}}
						text={edit}
					/>
				</Stack>
			</CardBody>
		</Card>
	);
};
