import { useParams } from 'react-router-dom';
import { environments as envTexts } from '../../../../@xmcloud/core/messages/en';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import { useGetProjectEnvironmentsList } from '../../../services/projects';
import { useEnvironmentsTableColumns } from '../columns/environments';
import { ColumnDef } from '@tanstack/react-table';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { useFeature } from '../../../feature-flag/features';
import { useCreateProjectV3 } from '../../create-project/old-components/useCreateProjectV3';

const headers = ['name', 'ID', 'Date created', ''];

const ProjectEnvironmentsTable: React.FC = () => {
	const { projectId, ...rest } = useParams<{
		projectId: string;
		'envType-environments': string;
	}>();

	const createProjectV2 = useFeature('CreateProjectV2');
	const { enableCreateProjectV3: isCreateProjectV3 } = useCreateProjectV3();

	const { data: environmentsData, isLoading: isEnvironmentsLoading } =
		useGetProjectEnvironmentsList({
			projectId: projectId!,
		});

	let environments = environmentsData?.data || [];

	const envType = rest['envType-environments']?.split('-')[0];
	if (isCreateProjectV3) {
		environments = environments.filter((env) => env.type === envType);
	}

	const columns: ColumnDef<IGetEnvironmentResponseV2, any>[] =
		useEnvironmentsTableColumns(envType);

	const isLoading = isEnvironmentsLoading;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	if (environments.length === 0)
		return (
			<RenderPlaceholder
				item={envTexts.environments.toLocaleLowerCase()}
			/>
		);

	return (
		<Table
			{...{
				columns: columns,
				tableData: environments,
				dataTestId: 'environments-table',
				showPagination: environments.length > pageSize,
				headers: createProjectV2
					? headers
					: [
							'name',
							'ID',
							'Context ID(Preview)',
							'Context ID(Live)',
							'Date created',
							'',
						],
			}}
		/>
	);
};

export default ProjectEnvironmentsTable;
