import { useState } from 'react';
import { Tooltip, useToast } from '@chakra-ui/react';
import { FC } from 'react';
import {
	environmentVariable,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	EnvVars,
	EnvVarsAddButton,
	EnvVarsForm,
	EnvVarsTable,
	HasEnvVars,
	EnvVariable,
	EnvVariableInput,
} from '../../../features/custom-environment-variables';
import { t } from '@transifex/native';
import {
	useDeleteEnvironmentVariableMutation,
	useGetEnvironmentVariables,
	useUpsertEnvironmentVariableMutation,
} from '../../../services/environments';
import { useMatch, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import {
	AlertDialogConfirmationModal,
	Wrap,
	Text,
} from '../../../shared-components';
import { VariablesPageLegacy } from './VariablesPageLegacy';
import {
	environmentPath,
	mainPaths,
} from '../../../../@xmcloud/core/routes/paths';
import { useCreateProjectV3 } from '../../create-project/old-components/useCreateProjectV3';
import { EnvironmentTypesEnum } from '../../create-project/helpers';

const {
	errMsg4,
	errMsg1,
	createVariable: createVariabletxt,
	configuration: configurationtxt,
} = systemHealthStatus;
const { CM, EH } = EnvironmentTypesEnum;

const TARGET = {
	[CM]: 'CM',
	[EH]: 'EH',
	combined: null,
};

export const VariablesPageLatest: FC = () => {
	const { environmentId, projectId, ...rest } = useParams<{
		environmentId: string;
		projectId: string;
		// Used for the updated route for V3
		'envType-environments': string;
	}>();
	const { isConfigurationHealthy, isError } = useComponentHealthy({});
	const { data: envVariables } = useGetEnvironmentVariables({
		environmentId: environmentId!,
	});
	const { mutateAsync: createVariable } =
		useUpsertEnvironmentVariableMutation();

	const { mutateAsync: deleteVariable, isLoading: isDeletionInProgress } =
		useDeleteEnvironmentVariableMutation();

	const queryClient = useQueryClient();
	const toast = useToast();

	const { environment_variables } = config.environments;
	const { createdSuccessfully } = environmentVariable;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [variableToDelete, setVariableToDelete] =
		useState<EnvVariableInput | null>(null);
	const envType = (rest['envType-environments']?.split('-')[0] ||
		'combined') as keyof typeof TARGET;

	const handleCreateVariable = (values: EnvVariableInput) => {
		return createVariable(
			{
				environmentId: environmentId!,
				variableName: values.name,
				data: {
					secret: values.secret,
					value: values.value,
					target: TARGET[envType] as string,
				},
			},
			{
				onSuccess: (_, variables) => {
					queryClient.invalidateQueries(
						environment_variables.queryKey(variables.environmentId),
					);
					toast({
						status: 'success',
						description: t(createdSuccessfully),
					});
				},
			},
		);
	};

	const handleDeleteVariable = (variable: EnvVariableInput) => {
		return new Promise((resolve) => {
			setVariableToDelete(variable);
			setIsModalOpen(true);
		});
	};

	const confirmDeleteVariable = () => {
		if (!variableToDelete) return;

		deleteVariable(
			{
				environmentId: environmentId!,
				variableName: variableToDelete.name,
			},
			{
				onSuccess: (_, variables) => {
					queryClient.invalidateQueries(
						config.environments.environment_variables.queryKey(
							variables.environmentId,
						),
					);
					toast({
						status: 'success',
						description: t(environmentVariable.deletedSuccessfully),
					});
					setIsModalOpen(false);
				},
			},
		);
	};

	const healthStatusLabel = isError
		? t(errMsg1)
		: t(errMsg4, {
				variableTxt: configurationtxt,
				variableTxt2: createVariabletxt,
			});

	return (
		<EnvVars
			isExistingProject={Boolean(projectId)}
			initialVariables={(envVariables?.data as EnvVariable[]) || []}
		>
			<Tooltip
				label={healthStatusLabel}
				isDisabled={isConfigurationHealthy}
			>
				<Wrap alignSelf="flex-end">
					<EnvVarsAddButton
						variant="solid"
						isDisabled={!isConfigurationHealthy}
					/>
				</Wrap>
			</Tooltip>
			<EnvVarsForm onMutationSubmit={handleCreateVariable} />
			<HasEnvVars>
				<EnvVarsTable onMutationDelete={handleDeleteVariable} />
			</HasEnvVars>
			<AlertDialogConfirmationModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onConfirm={confirmDeleteVariable}
				title={environmentVariable.delete}
				isLoading={isDeletionInProgress}
			>
				<Text text={t(environmentVariable.deleteConfirmation)}></Text>
			</AlertDialogConfirmationModal>
		</EnvVars>
	);
};

/**
 * Used as a controller to render the variables page for the environment.
 * It will render the latest version of the variables page if the feature flag is enabled.
 */
export function VariablesPage(): JSX.Element {
	const { enableCreateProjectV3: isCreateProjectV3 } = useCreateProjectV3();
	const isCmEnvironemtOrEhEvnironmentRoute = useMatch(
		`${mainPaths.environment}/${environmentPath.variables}`,
	);

	if (isCreateProjectV3 && isCmEnvironemtOrEhEvnironmentRoute) {
		return <VariablesPageLatest />;
	}

	return <VariablesPageLegacy />;
}
