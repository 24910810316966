import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
	Alert,
	AlertStatus,
	Icon,
	InputFormControl,
	ModalMainContent,
	ModalWrapper,
	SelectFormControl,
} from '../../../shared-components';
import {
	app,
	commonCw,
	editCreateEnvironment,
	fourthStep,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { useFormik } from 'formik';
import { editCreateEnvironmentSchema } from '../../../../@xmcloud/yup-validations';
import { useGetEnvironmentsLimitation } from '../../../services/projects';
import {
	useGetAllEnvironmentsLimitation,
	useGetEnvironmentsByQuery,
} from '../../../services/environments';
import { useParams } from 'react-router-dom';
import { t } from '@transifex/native';
import {
	useComponentHealthy,
	useProjectDetails,
	useValidateConnection,
} from '../../../../@xmcloud/hooks';
import { onDisabled } from '../../../../@xmcloud/utils/helpers';
import { TenantType } from '../../../../@xmcloud/types';
import { ADO_BRANCH_PREFIX } from '../../../../@xmcloud/utils/constants';
import { useGetGitHubPaginatedBranches } from '../../../services/githubApi';
import { useGetADOBranches } from '../../../services/adoApi';
import { CM_ENVIRONMENT_ID } from '../../create-project/helpers';
import { debounce } from 'lodash';
import { mdiAlert } from '@mdi/js';

const { NonProduction, Production } = TenantType;

const {
	environmentName,
	isThisProduction,
	linkToBranch,
	triggerDeployment,
	editTitle,
	createTitle,
	yes,
	no,
	branchHelperText1,
	branchHelperText2,
	errorPostfix,
	warningSuffix,
} = editCreateEnvironment;
const { availableEnvironments } = fourthStep;
const { retry } = commonCw;
const { save, create } = app;
const { errMsg3, errMsg4, envCreate, envEdit } = systemHealthStatus;

interface Props {
	openModal: boolean;
	setOpenModal: (a: boolean) => void;
	isEdit?: boolean;
	onSubmit: (values: any) => void;
	isLoading?: boolean;
	editInitialValues?: EditCreateEnvironmentFormProps;
	shouldRetry?: boolean;
}

const NAME = 'name';
const TENANT_TYPE = 'tenantType';
const REPOSITORY_BRANCH = 'repositoryBranch';
const DEPLOY_ON_COMMIT = 'deployOnCommit';

const EditCreateEnvironmentForm: FC<Props> = ({
	openModal,
	setOpenModal,
	isEdit = false,
	onSubmit,
	isLoading = false,
	editInitialValues,
	shouldRetry = false,
}) => {
	const { projectId } = useParams<{ projectId: string }>();
	const inputQuery = useRef('');

	const { project, projectType } = useProjectDetails(projectId!);
	const repository = project?.data.repository;
	const sourceControlIntegrationId =
		project?.data.sourceControlIntegrationId || '';
	const owner = project?.data.sourceControlIntegrationName;

	const isEHTypeProject = projectType === 'eh';

	const { data: environmentPerProjectLimitation } =
		useGetEnvironmentsLimitation({
			projectId: projectId!,
			enabled: !isEdit,
		});

	const { data: allEnvironmentsLimitation } =
		useGetAllEnvironmentsLimitation(!isEdit);

	const validationSchema = editCreateEnvironmentSchema(
		environmentPerProjectLimitation,
		allEnvironmentsLimitation,
		isEdit,
		isEHTypeProject,
	);

	const initialValues = useRef(
		isEdit && editInitialValues
			? editInitialValues
			: {
					name: '',
					tenantType: NonProduction,
					repositoryBranch: '',
					deployOnCommit: false,
					linkEnvironmentToDefaultBranch: false,
					...(isEHTypeProject && { cmEnvironmentId: '' }),
				},
	);
	const {
		isLoading: isValidationLoading,
		isNotValidIntegration,
		integrationIdValidationMsg,
		provider,
		token,
	} = useValidateConnection({
		sourceControlIntegrationId,
	});

	const {
		data: environmentsData,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useGetEnvironmentsByQuery({
		_enabled: isEHTypeProject,
	});

	const environmentsOptions = useMemo(() => {
		return (
			environmentsData?.map((e) => ({
				label: `${e.projectName} / ${e.name}`,
				value: e.id,
			})) || []
		);
	}, [environmentsData]);

	const {
		isBlockersHealthy,
		isAdoProvider,
		isGithubProvider,
		isLoading: isHealthStatusLoading,
		isCurrentProviderHealthy,
		getUnhealthyBlockerList,
	} = useComponentHealthy({ provider: provider || 0 });

	const isProviderUnHealthy =
		!isCurrentProviderHealthy || isNotValidIntegration;

	const hasProvider = isAdoProvider || isGithubProvider;

	const integrationErrorMessage = !isCurrentProviderHealthy
		? `${isAdoProvider ? 'Azure DevOps' : 'GitHub'} is not healthy.`
		: integrationIdValidationMsg;

	const disableCreateBtn = !isBlockersHealthy && !isEdit;

	const listOfBlockers = [
		...(!isBlockersHealthy && !isEdit
			? [...getUnhealthyBlockerList()]
			: []),
	];

	const showErrorAlert =
		listOfBlockers.length && disableCreateBtn && !isHealthStatusLoading;

	const showWarningAlert =
		isProviderUnHealthy &&
		!isHealthStatusLoading &&
		!showErrorAlert &&
		!isValidationLoading &&
		hasProvider;

	const {
		data: gitRepositoryBranchData,
		isLoading: isGitBranchLoading,
		hasMore,
		isFetching: isFetchingGithubBranches,
		fetchNextPage: fetchNextPageGithubBranches,
	} = useGetGitHubPaginatedBranches({
		token,
		owner: owner!,
		repo: repository!,
		_enabled: isGithubProvider,
		onSuccess: onGithubBranchesSuccess,
	});

	let splitRepoInfo = null;
	if (isAdoProvider) {
		splitRepoInfo = repository?.split('/');
	}

	const { data: adoRepositoryBranchData, isLoading: isAdoBranchLoading } =
		useGetADOBranches(
			token,
			splitRepoInfo ? splitRepoInfo[0] : '', // Organization
			splitRepoInfo ? splitRepoInfo[1] : '', // Project
			splitRepoInfo ? splitRepoInfo[2] : '', // Repository
			isAdoProvider,
		);

	const isRepositoryBranchLoading =
		isGitBranchLoading || isValidationLoading || isAdoBranchLoading;

	const repositoryBranchOptions = useMemo(() => {
		const adoBranches = adoRepositoryBranchData?.data.value || [];
		const githubBranches = gitRepositoryBranchData || [];

		const repos = isAdoProvider
			? adoBranches
			: isGithubProvider
				? githubBranches
				: [];

		const mappedAdoBranches = repos.map((branch: any) => ({
			label: branch.name.replace(ADO_BRANCH_PREFIX, ''),
			value: branch.name.replace(ADO_BRANCH_PREFIX, ''),
		}));

		const noneRepo = mappedAdoBranches.find((b: any) => b.label === 'None');

		if (!noneRepo?.label) {
			mappedAdoBranches.push({ label: 'None', value: '' });
		}

		return mappedAdoBranches;
	}, [
		adoRepositoryBranchData?.data.value,
		gitRepositoryBranchData,
		isAdoProvider,
		isGithubProvider,
	]);

	function onGithubBranchesSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasMore || !isGithubProvider || !lastPage.length)
			return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPageGithubBranches();
		}
	}

	const onInputChange = useCallback(
		(e: string) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasMore ||
				!isGithubProvider ||
				!repositoryBranchOptions?.length ||
				isFetching
			)
				return;

			const hasRepo = repositoryBranchOptions.some((r: any) =>
				r.label.includes(e),
			);

			if (!hasRepo) {
				fetchNextPageGithubBranches();
			}
		},
		[
			fetchNextPageGithubBranches,
			hasMore,
			isFetching,
			isGithubProvider,
			repositoryBranchOptions,
		],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	const {
		errors,
		values,
		handleChange,
		handleSubmit: formikHandleSubmit,
		setFieldValue,
		setFieldTouched,
		touched,
		isValid,
	} = useFormik({
		enableReinitialize: true,
		initialValues: initialValues.current,
		validationSchema,
		validateOnBlur: false,
		onSubmit: async (v) => {
			const cmEnvironmentId = v?.cmEnvironmentId;
			const values = { ...v };
			delete values.cmEnvironmentId;
			const payload = {
				...values,
				...(isEHTypeProject &&
					!isEdit && {
						editingHostEnvironmentDetails: {
							cmEnvironmentId,
						},
					}),
			};
			onSubmit(payload);
		},
	});

	useEffect(() => {
		if (
			values.repositoryBranch === '' &&
			!isEdit &&
			repositoryBranchOptions.length
		) {
			const masterOrMainBranch = repositoryBranchOptions.find(
				(b: any) => b.value === 'main' || b.value === 'master',
			);

			const repositoryBranch: string = Boolean(masterOrMainBranch)
				? masterOrMainBranch?.value
				: repositoryBranchOptions[0].value;

			setFieldValue(REPOSITORY_BRANCH, repositoryBranch);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [repositoryBranchOptions]);

	const isFormLoading =
		isLoading ||
		isRepositoryBranchLoading ||
		isValidationLoading ||
		isHealthStatusLoading;

	const isDisabled = isEdit
		? onDisabled(isValid, values, editInitialValues) || isFormLoading
		: disableCreateBtn;

	return (
		<ModalWrapper
			isOpen={openModal}
			title={isEdit ? editTitle : createTitle}
			onClose={() => setOpenModal(false)}
		>
			<form
				data-testid="edit-create-environment-form"
				onSubmit={formikHandleSubmit}
			>
				<ModalMainContent
					onClose={() => setOpenModal(false)}
					rightButtonText={
						shouldRetry ? retry : isEdit ? save : create
					}
					isLoading={isFormLoading}
					isDisabled={isDisabled}
				>
					<InputFormControl
						{...{
							isInvalid: Boolean(errors.name && touched.name),
							label: environmentName,
							name: NAME,
							value: values.name,
							onChange: handleChange,
							error: errors.name,
							onFocus: () => setFieldTouched(NAME, true),
						}}
					/>
					{isEHTypeProject && !isEdit && (
						<SelectFormControl
							{...{
								isInvalid: Boolean(
									errors.cmEnvironmentId &&
										touched.cmEnvironmentId,
								),
								isLoading: isFetching,
								options: environmentsOptions,
								onChange: (e: any) => {
									setFieldValue(CM_ENVIRONMENT_ID, e.value);
								},
								label: availableEnvironments,
								name: CM_ENVIRONMENT_ID,
								currentValue: values.cmEnvironmentId,
								error: errors.cmEnvironmentId,
								onFocus: () =>
									setFieldTouched(CM_ENVIRONMENT_ID, true),
								onMenuScrollToBottom: () => {
									hasNextPage && fetchNextPage();
								},
							}}
						/>
					)}
					<SelectFormControl
						{...{
							showAsPlaceHolder: isEdit,
							error: errors.tenantType,
							isInvalid: Boolean(errors.tenantType),
							options: tenantTypeOptions,
							onChange: (e: any) => {
								setFieldValue(TENANT_TYPE, e.value);
							},
							label: isThisProduction,
							name: TENANT_TYPE,
							currentValue: values.tenantType,
							helperText: branchHelperText1,
							helperBoldText: branchHelperText2,
							helperTextIcon: (
								<Icon
									path={mdiAlert}
									color="warning"
									mb={0.5}
									mr={1}
								/>
							),
						}}
					/>
					<SelectFormControl
						{...{
							error: errors.repositoryBranch,
							isInvalid: Boolean(errors.repositoryBranch),
							isLoading:
								isRepositoryBranchLoading ||
								isFetchingGithubBranches,
							options: repositoryBranchOptions,
							onChange: (e: any) => {
								setFieldValue(REPOSITORY_BRANCH, e.value);
								if (e.value === '')
									setFieldValue(DEPLOY_ON_COMMIT, false);
							},
							label: linkToBranch,
							name: REPOSITORY_BRANCH,
							currentValue: values.repositoryBranch,
							isDisabled:
								!Boolean(repositoryBranchOptions.length > 1) ||
								isProviderUnHealthy,
							onInputChange: debouncedChangeHandler,
							onMenuScrollToBottom: () => {
								if (
									hasMore &&
									isGithubProvider &&
									!isFetchingGithubBranches
								) {
									fetchNextPageGithubBranches();
								}
							},
						}}
					/>
					<SelectFormControl
						{...{
							error: errors.deployOnCommit,
							isInvalid: Boolean(errors.deployOnCommit),
							options: deployOnCommitOptions,
							onChange: (e: any) => {
								setFieldValue(DEPLOY_ON_COMMIT, e.value);
							},
							label: triggerDeployment,
							name: DEPLOY_ON_COMMIT,
							currentValue: values.deployOnCommit,
							isDisabled:
								!Boolean(values.repositoryBranch) ||
								isProviderUnHealthy,
						}}
					/>
					{Boolean(showWarningAlert) && (
						<Alert
							description={`${integrationErrorMessage}${
								isEdit ? warningSuffix : errorPostfix
							}`}
							status="warning"
							marginTop={4}
							boldTextTransform="none"
							data-testid="warning-alert-component"
						/>
					)}
					{!!showErrorAlert && (
						<AlertStatus
							description={
								listOfBlockers.length > 1 ? errMsg3 : errMsg4
							}
							status="error"
							data-testid="error-alert-component"
							variableTxt={listOfBlockers.join(', ')}
							marginTop={4}
							variableTxt2={isEdit ? envEdit : envCreate}
						/>
					)}
				</ModalMainContent>
			</form>
		</ModalWrapper>
	);
};

export default EditCreateEnvironmentForm;

const deployOnCommitOptions = [
	{ label: t(no), value: false },
	{ label: t(yes), value: true },
];

const tenantTypeOptions = [
	{ label: t(no), value: NonProduction },
	{ label: t(yes), value: Production },
];

export type EditCreateEnvironmentFormProps = {
	name: string;
	tenantType: TenantType;
	repositoryBranch: string;
	deployOnCommit: boolean;
	linkEnvironmentToDefaultBranch: boolean;
	cmEnvironmentId?: string;
};
