import {
	useCreateProject,
	useEnvironmentCreation,
	useGitHubProjectCreation,
} from '../../../../../@xmcloud/hooks';
import { ReviewFinish } from '..';
import { EnvironmentTypesEnum } from '../../helpers';
import { useState } from 'react';

const { EH } = EnvironmentTypesEnum;

export const GitHubReviewFinish: React.FC<{
	token: string;
	isLoading: boolean;
}> = ({ token, isLoading: initialLoading }) => {
	const { isByoc, isSingleEnvironmentCreation, values } = useCreateProject();
	const [isCmEnvironmentSuccess, setIsCmEnvironmentSuccess] = useState(false);
	const [detail, setDetail] = useState({
		cmEnvironmentId: '',
		projectId: '',
	});

	function onCmEnvironmentCreationSuccess(
		cmEnvironmentId: string,
		projectId: string,
	) {
		setDetail({ cmEnvironmentId, projectId });
		setIsCmEnvironmentSuccess(true);
		setDetail({ cmEnvironmentId, projectId });
		onEnvironmentLevelProcess(projectId, cmEnvironmentId);
	}

	const {
		isLoading: isGitHubProjectCreationLoading,
		onHandleCreationProcess,
		repositoryCreationStatus: cmRepositoryCreationStatus,
		projectCreationStatus: cmProjectCreationStatus,
		environmentCreationStatus: cmEnvironmentCreationStatus,
		deploymentCreationStatus: cmDeploymentCreationStatus,
		environmentVariableCreationStatus: cmEnvironmentVariableCreationStatus,
		shouldRetry,
	} = useGitHubProjectCreation({
		token,
		onCmEnvironmentCreationSuccess,
	});

	const {
		isLoading: isLoadingCreateEnvironment,
		onEnvironmentLevelProcess,
		environmentCreationStatus: ehEnvironmentCreationStatus,
		deploymentCreationStatus: ehDeploymentCreationStatus,
		environmentVariableCreationStatus: ehEnvironmentVariableCreationStatus,
	} = useEnvironmentCreation({
		shouldGoto: true,
		environmentType: EH,
	});

	const listOfStatus = [
		...(isByoc
			? []
			: [
					{
						status: cmRepositoryCreationStatus,
						text: `Repository`,
					},
				]),

		{ status: cmProjectCreationStatus, text: `Project` },
		{
			status: cmEnvironmentCreationStatus,
			text: `CM environment`,
		},
		{
			status: cmDeploymentCreationStatus,
			text: `CM environment deployment`,
		},
		...(values.cmEnvironmentVariables.length
			? [
					{
						status: cmEnvironmentVariableCreationStatus,
						text: `CM environment variables`,
					},
				]
			: []),
		...(isSingleEnvironmentCreation
			? []
			: [
					{
						status: ehEnvironmentCreationStatus,
						text: `Editing host environment`,
					},
					{
						status: ehDeploymentCreationStatus,
						text: `Editing host environment deployment`,
					},
					...(values.ehEnvironmentVariables.length
						? [
								{
									status: ehEnvironmentVariableCreationStatus,
									text: `Editing host environment variables`,
								},
							]
						: []),
				]),
	];

	const startCreateProject = () => {
		!isCmEnvironmentSuccess && onHandleCreationProcess();
		const { cmEnvironmentId, projectId } = detail;
		isCmEnvironmentSuccess &&
			onCmEnvironmentCreationSuccess(cmEnvironmentId, projectId);
	};

	const isLoading =
		isLoadingCreateEnvironment ||
		isGitHubProjectCreationLoading ||
		initialLoading;

	return (
		<ReviewFinish
			{...{
				isLoading,
				shouldRetry,
				handleStartDeployment: startCreateProject,
				listOfStatus,
			}}
		/>
	);
};
