import { useParams } from 'react-router-dom';
import { createProjectSteps } from './helpers';
import { useRedirect404 } from './old-components/useRedirect404';
import { Box, Stack } from '@chakra-ui/react';
import { ChakraStepper } from '../../shared-components';
import { useCreateProject } from '../../../@xmcloud/hooks';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { useEffect } from 'react';
import _ from 'lodash';

const { createProject } = goTo;

export const CwMain: React.FC = () => {
	const { step: stepParam } = useParams<{ step: string }>();

	useRedirect404();

	const { formikHandleSubmit, step, values, activeStep, setActiveStep } =
		useCreateProject();

	function handleStepChange(index: number) {
		setActiveStep(index);
		handlePush(createProject(index));
	}

	useEffect(() => {
		//param interval includes 1-length of steps
		if (_.inRange(Number(stepParam), 1, createProjectSteps.length + 1)) {
			if (Number(stepParam) === activeStep) return;
			if (!values.name) {
				handleStepChange(1);
			}
		} else {
			handleStepChange(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepParam]);

	return (
		<Stack direction={['column', 'column', 'row', 'row']} pt="6">
			<Box w={['100%', '100%', '25%', '25%']} pb="4">
				<ChakraStepper
					{...{
						activeStep,
						setActiveStep,
						path: 'createProject',
						steps: createProjectSteps,
					}}
				/>
			</Box>
			<Box w={['100%', '100%', '75%', '75%']}>
				<form
					data-testid="create-project-wizard-form"
					onSubmit={formikHandleSubmit}
				>
					{step}
				</form>
			</Box>
		</Stack>
	);
};
