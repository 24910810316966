import { SelectADOBranch } from '..';
import { useMemo } from 'react';
import {
	EnvironmentTypesEnum,
	TFormikCommonProps,
	TOptions,
	adoOrganizationNamespaces,
	adoProjectNamespaces,
	repositoryBranchNamespaces,
	repositoryIdNamespaces,
	repositoryNameSpaces,
	repositoryRelativePathNamespaces,
	sourceControlIntegrationIdNameSpaces,
} from '../../helpers';
import {
	InputFormControl,
	InputFormControlWithTransformationMessage,
	SelectFormControl,
} from '../../../../shared-components';
import { Stack } from '@chakra-ui/react';
import { FormikValues } from '../../../../../@xmcloud/hooks/context/CreateProjectContext';
import {
	fourthStep,
	secondStep,
} from '../../../../../@xmcloud/core/messages/en';
import {
	useGetADOOrganizations,
	useGetADOProfile,
	useGetADOProjects,
	useGetADORepositories,
} from '../../../../services/adoApi';

const { selectProject, selectOrg, info2, nameYourRepo } = secondStep;
const { fieldLabel1, desc1 } = fourthStep;
const { selectRepo } = secondStep;

export const AdoRepository: React.FC<
	{
		isIntegrationIdValidationValid: boolean;
		isSourceControlIntegrationLoading: boolean;
		accessToken: string;
		nameSpace?: EnvironmentTypesEnum | 'default';
		setValues: (values: any) => void | any;
		setFieldValue: (field: string, value: any) => void;
		setFieldTouched: any;
		isByoc?: boolean;
		handleChange: any;
	} & TFormikCommonProps
> = ({
	isIntegrationIdValidationValid,
	accessToken,
	isSourceControlIntegrationLoading,
	nameSpace = 'default',
	values,
	errors,
	touched,
	setValues,
	setFieldValue,
	setFieldTouched,
	handleChange,
	isByoc = false,
}) => {
	const repositoryNamespace = repositoryNameSpaces[nameSpace];
	const repositoryIdNamespace = repositoryIdNamespaces[nameSpace];
	const repositoryBranchNamespace = repositoryBranchNamespaces[nameSpace];
	const sourceControlIntegrationIdNameSpace =
		sourceControlIntegrationIdNameSpaces[nameSpace];
	const repositoryRelativePathNameSpace =
		repositoryRelativePathNamespaces[nameSpace];
	const adoOrganizationNamespace = adoOrganizationNamespaces[nameSpace];
	const adoProjectNamespace = adoProjectNamespaces[nameSpace];

	const sourceControlIntegrationId =
		values[sourceControlIntegrationIdNameSpace];

	const { data: profileData, isLoading: isLoadingProfile } = useGetADOProfile(
		accessToken,
		sourceControlIntegrationId,
	);
	const publicAlias = profileData?.data.publicAlias || '';

	const { data: organizationsData, isLoading: isAdoOrganizationLoading } =
		useGetADOOrganizations({
			token: accessToken,
			publicAlias,
			_enabled: isIntegrationIdValidationValid && !!accessToken,
		});

	const { data: projectsData, isLoading: isLoadingProjects } =
		useGetADOProjects({
			token: accessToken,
			organization: values[adoOrganizationNamespace],
			_enabled: isIntegrationIdValidationValid && !!accessToken,
		});

	const { data: repositoriesData, isFetching: isFetchingRepositories } =
		useGetADORepositories({
			token: accessToken,
			organization: values[adoOrganizationNamespace],
			project: values[adoProjectNamespace],
			_enabled: isIntegrationIdValidationValid && !!accessToken,
		});

	const adoOrganizations = useMemo(
		() =>
			organizationsData?.data.value.map((r: any) => ({
				label: r.accountName,
				value: r.accountName,
			})) || [],
		[organizationsData?.data.value],
	);

	const adoProjects = useMemo(
		() =>
			projectsData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [],
		[projectsData?.data.value],
	);
	const repositoryOptions: TOptions = useMemo(
		() =>
			repositoriesData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
				id: r.id,
			})) || [],
		[repositoriesData?.data.value],
	);

	const onOrganizationChange = (e: any) => {
		setValues((prev: FormikValues) => ({
			...prev,
			[adoOrganizationNamespace]: e.value || '',
			[adoProjectNamespace]: '',
			[repositoryNamespace]: '',
			[repositoryIdNamespace]: '',
			[repositoryBranchNamespace]: '',
		}));
	};
	const onProjectChange = (e: any) => {
		setValues((prev: FormikValues) => ({
			...prev,
			[adoProjectNamespace]: e.value || '',
			[repositoryNamespace]: '',
			[repositoryIdNamespace]: '',
			[repositoryBranchNamespace]: '',
		}));
	};
	const onRepositoryChange = (e: any) => {
		setValues((prev: FormikValues) => ({
			...prev,
			[repositoryNamespace]: e.label || '',
			[repositoryIdNamespace]: e.id || '',
			[repositoryBranchNamespace]: '',
		}));
	};

	return (
		<Stack gap="7">
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors[adoOrganizationNamespace] &&
							touched[adoOrganizationNamespace],
					),
					isLoading:
						isAdoOrganizationLoading ||
						isLoadingProfile ||
						isSourceControlIntegrationLoading,
					options: adoOrganizations,
					onChange: onOrganizationChange,
					label: selectOrg,
					name: adoOrganizationNamespace,
					currentValue: values[adoOrganizationNamespace],
					onFocus: () =>
						setFieldTouched(adoOrganizationNamespace, true),
					error: errors[adoOrganizationNamespace],
					isRequired: true,
					pt: 0,
				}}
			/>
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors[adoProjectNamespace] &&
							touched[adoProjectNamespace],
					),
					isLoading:
						isLoadingProjects || isSourceControlIntegrationLoading,
					options: adoProjects,
					onChange: onProjectChange,
					label: selectProject,
					name: adoProjectNamespace,
					currentValue: values[adoProjectNamespace],
					onFocus: () => setFieldTouched(adoProjectNamespace, true),
					error: errors[adoProjectNamespace],
					isRequired: true,
					pt: 0,
				}}
			/>
			{isByoc && (
				<SelectFormControl
					{...{
						error: errors[repositoryNamespace],
						isInvalid: Boolean(
							errors[repositoryNamespace] &&
								touched[repositoryNamespace],
						),
						isLoading:
							isFetchingRepositories ||
							isSourceControlIntegrationLoading,
						options: repositoryOptions,
						onChange: onRepositoryChange,
						label: selectRepo,
						name: repositoryNamespace,
						currentValue: values[repositoryNamespace],
						onFocus: () =>
							setFieldTouched(repositoryNamespace, true),
						isRequired: true,
						pt: 0,
					}}
				/>
			)}
			{isByoc && (
				<SelectADOBranch
					{...{
						token: accessToken,
						nameSpace,
						values,
						errors,
						touched,
						setFieldValue,
					}}
				/>
			)}
			{!isByoc && (
				<InputFormControlWithTransformationMessage
					{...{
						isInvalid: Boolean(
							errors[repositoryNamespace] &&
								touched[repositoryNamespace],
						),
						label: nameYourRepo,
						name: repositoryNamespace,
						value: values[repositoryNamespace],
						pt: 0,
						onChange: handleChange,
						error: errors[repositoryNamespace],
						onFocus: () =>
							setFieldTouched(repositoryNamespace, true),
						setFieldValue,
						isRequired: true,
						helperText: info2,
					}}
				/>
			)}
			{isByoc && (
				<InputFormControl
					{...{
						isInvalid: Boolean(
							errors[repositoryRelativePathNameSpace] &&
								touched[repositoryRelativePathNameSpace],
						),
						label: fieldLabel1,
						name: repositoryRelativePathNameSpace,
						value: values[repositoryRelativePathNameSpace],
						pt: 0,
						onChange: handleChange,
						error: errors[repositoryRelativePathNameSpace],
						onFocus: () =>
							setFieldTouched(
								repositoryRelativePathNameSpace,
								true,
							),
						isRequired: true,
						helperText: desc1,
					}}
				/>
			)}
		</Stack>
	);
};
