import { Stack } from '@chakra-ui/react';
import { CheckboxFormControl, HeadingMd } from '../../../shared-components';
import {
	deployOnCommitNamespaces,
	EnvironmentTypesEnum,
	TENANT_TYPE,
} from '../helpers';
import { thirdStep } from '../../../../@xmcloud/core/messages/en';
import { TenantType } from '../../../../@xmcloud/types';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
const { info2, info3, prodEnv, autoDeploy, options } = thirdStep;

const { Production, NonProduction } = TenantType;

export const Options: React.FC<{
	showSLA?: boolean;
	setFieldValue: any;
	nameSpace?: EnvironmentTypesEnum | 'default';
	values: FormikValues;
}> = ({ showSLA = true, setFieldValue, values, nameSpace = 'default' }) => {
	const deployOnCommitNamespace = deployOnCommitNamespaces[nameSpace];

	return (
		<Stack gap="6">
			<HeadingMd text={options} />
			<CheckboxFormControl
				{...{
					label: autoDeploy,
					name: deployOnCommitNamespace,
					isChecked: values[deployOnCommitNamespace],
					onChange: (e) =>
						setFieldValue(
							deployOnCommitNamespace,
							e.target.checked,
						),
					helperText: info3,
					pt: 1,
				}}
			/>
			{showSLA && (
				<CheckboxFormControl
					{...{
						label: prodEnv,
						name: TENANT_TYPE,
						isChecked: values.tenantType === Production,
						onChange: (e) =>
							setFieldValue(
								TENANT_TYPE,
								e.target.checked ? Production : NonProduction,
							),
						helperText: info2,
					}}
				/>
			)}
		</Stack>
	);
};
