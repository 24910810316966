import { Button, ButtonGroup, Stack, Tooltip, Wrap } from '@chakra-ui/react';
import {
	ACCOUNT_TYPE,
	adoOrganizationNamespaces,
	adoProjectNamespaces,
	connectionNameSpaces,
	EnvironmentTypesEnum,
	providerNameSpaces,
	repositoryBranchNamespaces,
	repositoryIdNamespaces,
	repositoryNameSpaces,
	sourceControlIntegrationIdNameSpaces,
	TFormikCommonProps,
} from '../helpers';
import {
	connections,
	secondStep,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	EnumInNumberSourceControl,
	ESourceControl,
	SourceControlType,
} from '../../../../@xmcloud/types';
import {
	FormControl,
	HeadingMd,
	SelectFormControl,
	XMCSButton,
} from '../../../shared-components';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import {
	adoIntegrationUrl,
	githubIntegrationUrl,
	NEW_SOURCE_CONTROL_ACCOUNT,
} from '../../../../@xmcloud/utils/constants';
import {
	useGetRepositoryIntegrations,
	useRepositoryIntegrateState,
} from '../../../services/repositories';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';

const { github, ado, sourceControlProvider } = connections;
const { info1, addAzure, addGithub, azureAccount, githubAccount } = secondStep;
const { githubStatus, adoStatus } = systemHealthStatus;

const { validate_integration_V2 } = config.repository;

const { GitHub, ADO } = ESourceControl;
const { GitHub: GitHubInNumber, ADO: AdoInNumber } = EnumInNumberSourceControl;

export const SetupProviderAccount: React.FC<
	{
		nameSpace?: EnvironmentTypesEnum | 'default';
		isIntegrationIdValidationValid: boolean;
		integrationValidationErrorMsg: string;
		isSourceControlIntegrationLoading: boolean;
		setValues: (values: any) => void | any;
		setTouched: any;
		setErrors: any;
		setFieldTouched: any;
	} & TFormikCommonProps
> = ({
	values,
	errors,
	touched,
	nameSpace = 'default',
	setValues,
	setTouched,
	setErrors,
	setFieldTouched,
	isIntegrationIdValidationValid,
	integrationValidationErrorMsg,
	isSourceControlIntegrationLoading,
}) => {
	const providerNameSpace = providerNameSpaces[nameSpace];
	const connectionNameSpace = connectionNameSpaces[nameSpace];
	const sourceControlIntegrationIdNameSpace =
		sourceControlIntegrationIdNameSpaces[nameSpace];
	const repositoryNamespace = repositoryNameSpaces[nameSpace];
	const repositoryIdNamespace = repositoryIdNamespaces[nameSpace];
	const repositoryBranchNamespace = repositoryBranchNamespaces[nameSpace];
	const adoOrganizationNamespace = adoOrganizationNamespaces[nameSpace];
	const adoProjectNamespace = adoProjectNamespaces[nameSpace];

	const currentProvider = values[providerNameSpace];
	const isGithub = currentProvider === GitHub;
	const isAdo = currentProvider === ADO;
	const { isADOHealthy, isGithubHealthy, isLoading } = useComponentHealthy({
		provider: isGithub ? GitHubInNumber : isAdo ? AdoInNumber : 0,
	});

	const queryClient = useQueryClient();

	const {
		data: repoIntegrationData,
		isLoading: isFetchingIntegrationLoading,
	} = useGetRepositoryIntegrations();

	const repositoryIntegrations = useMemo(
		() =>
			repoIntegrationData?.data.filter(
				(i) => i.provider === currentProvider,
			) || [],
		[repoIntegrationData, currentProvider],
	);

	const integrationsOptions =
		repositoryIntegrations.map(({ account, id }) => ({
			label: account,
			value: id,
		})) || [];

	const {
		refetch: fetchIntegrateState,
		isFetching: isFetchingIntegrateState,
	} = useRepositoryIntegrateState({ enabled: false });

	useEffect(() => {
		const channel = new BroadcastChannel(NEW_SOURCE_CONTROL_ACCOUNT);

		channel.addEventListener('message', (e) => {
			if (e.origin !== window.location.origin) return;
			queryClient.invalidateQueries(
				validate_integration_V2.queryKey(e.data.id),
			);
			setTouched({});
			setErrors({});
			setValues((prev: FormikValues) => ({
				...prev,
				[connectionNameSpace]: e.data.account,
				[sourceControlIntegrationIdNameSpace]: e.data.id,
				[providerNameSpace]: e.data.provider,
				[ACCOUNT_TYPE]: e.data.accountType,
			}));
		});

		return () => {
			channel.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setValues]);

	useEffect(() => {
		if (!isGithubHealthy && isGithub) {
			setValues((prev: FormikValues) => ({
				...prev,
				[providerNameSpace]: '',
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const onProviderChange = (provider: SourceControlType) => {
		setValues((prev: FormikValues) => ({
			...prev,
			[repositoryNamespace]: '',
			[repositoryIdNamespace]: '',
			[sourceControlIntegrationIdNameSpace]: '',
			[connectionNameSpace]: '',
			[repositoryBranchNamespace]: '',
			[providerNameSpace]: provider,
		}));
		setTouched({});
	};
	const onConnectionChange = (e: any) => {
		const integration = repositoryIntegrations?.find(
			(i: any) => i.id === e.value,
		);
		if (!integration?.provider) return;
		setValues((prev: FormikValues) => ({
			...prev,
			[sourceControlIntegrationIdNameSpace]: e.value || '',
			[connectionNameSpace]: integration?.account,
			[providerNameSpace]: integration.provider as SourceControlType,
			[ACCOUNT_TYPE]: integration?.accountType || 0,
			[repositoryNamespace]: '',
			[repositoryIdNamespace]: '',
			[repositoryBranchNamespace]: '',
			[adoOrganizationNamespace]: '',
			[adoProjectNamespace]: '',
		}));
	};

	return (
		<Stack gap="4">
			<HeadingMd text="Repository" />
			<FormControl
				isRequired={true}
				label={sourceControlProvider}
				error={errors[providerNameSpace] || ''}
			>
				<ButtonGroup
					variant="toggle"
					size="sm"
					layerStyle="toggleGroup"
				>
					<Tooltip label={githubStatus} isDisabled={isGithubHealthy}>
						<Button
							onClick={() => onProviderChange(GitHub)}
							data-testid="github-provider-btn"
							isActive={isGithub}
							isDisabled={!isGithubHealthy}
						>
							{github}
						</Button>
					</Tooltip>
					<Tooltip label={adoStatus} isDisabled={isADOHealthy}>
						<Button
							onClick={() => onProviderChange(ADO)}
							data-testid="ado-provider-btn"
							isActive={isAdo}
							isDisabled={!isADOHealthy}
						>
							{ado}
						</Button>
					</Tooltip>
				</ButtonGroup>
			</FormControl>
			<Stack gap="2">
				<SelectFormControl
					{...{
						isInvalid: Boolean(
							(errors[sourceControlIntegrationIdNameSpace] ||
								!isIntegrationIdValidationValid) &&
								touched[sourceControlIntegrationIdNameSpace],
						),
						isLoading:
							isFetchingIntegrationLoading ||
							isSourceControlIntegrationLoading,
						options: integrationsOptions,
						onChange: onConnectionChange,
						label: isGithub ? githubAccount : azureAccount,
						name: sourceControlIntegrationIdNameSpace,
						currentValue:
							values[sourceControlIntegrationIdNameSpace],
						error:
							errors[sourceControlIntegrationIdNameSpace] ||
							integrationValidationErrorMsg,
						pt: 2,
						onFocus: () =>
							setFieldTouched(
								sourceControlIntegrationIdNameSpace,
								true,
							),
						isRequired: true,
						textContent: info1,
					}}
				/>
				<Wrap>
					<XMCSButton
						text={isGithub ? addGithub : addAzure}
						isLoading={isFetchingIntegrateState}
						variant="link"
						onClick={() => {
							if (values[providerNameSpace]) {
								fetchIntegrateState().then(({ data }) => {
									const state = data?.data ?? '';
									window.open(
										isGithub
											? githubIntegrationUrl(state)
											: isAdo
												? adoIntegrationUrl(state)
												: '#',
									);
								});
							}
						}}
					/>
				</Wrap>
			</Stack>
		</Stack>
	);
};
