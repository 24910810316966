import { useCreateProject } from '../../../../@xmcloud/hooks';
import { ESourceControl, SourceControlType } from '../../../../@xmcloud/types';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { ADOReviewFinish, GitHubReviewFinish } from '../components';

const { ADO, GitHub } = ESourceControl;

const reviewFinishLookup = {
	github: (props: any) => <GitHubReviewFinish {...props} />,
	ado: (props: any) => <ADOReviewFinish {...props} />,
	noSourceControl: () => <></>,
};

const getKey = (cmProvider: SourceControlType) =>
	cmProvider === GitHub
		? 'github'
		: cmProvider === ADO
			? 'ado'
			: 'noSourceControl';

export const ReviewFinishMain: React.FC = () => {
	const { values } = useCreateProject();
	const cmProvider = values.cmProvider;
	const currentKey = getKey(cmProvider);

	const { data: accessTokenData, isLoading: isAccessTokenLoading } =
		useGetSourceControlAccessToken(values.cmSourceControlIntegrationId);
	const token = accessTokenData?.data.accessToken || '';

	const isLoading = isAccessTokenLoading;

	const ReviewFinishComponent = reviewFinishLookup[currentKey];
	return <ReviewFinishComponent {...{ token, isLoading }} />;
};
