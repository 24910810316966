import { FC, useMemo, useState, useRef } from 'react';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { QueryResponse } from '../../../../@xmcloud/types';
import { IGetUserData } from '../../../models/userModel';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { IGetProjectResponseV2 } from '../../../models/projectModel';
import { projectsTableColumns, TProjects } from './columns';
import {
	CreateItemPlaceHolder,
	LoadingSkeleton,
	Table,
} from '../../../shared-components';
import { projects as enProjects } from '../../../../@xmcloud/core/messages/en';
import DeleteProject from '../../project/components/DeleteProject';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import { ColumnDef } from '@tanstack/react-table';
import { useFeature } from '../../../feature-flag/features';
import { useCreateProjectV3 } from '../../create-project/old-components/useCreateProjectV3';

const { uDontHaveProject: uDontHaveProjecttxt } = enProjects;

interface Props {
	selectMyProjects: boolean;
	isCreateButtonDisabled: boolean;
	allProjects: IGetProjectResponseV2[];
	isLoading: boolean;
}

const headers = [
	'name',
	'Project type',
	'Project ID',
	'Nu of Env',
	'Last updated',
	'',
];

export const ProjectsTable: FC<Props> = ({
	selectMyProjects,
	isCreateButtonDisabled,
	allProjects,
	isLoading,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const projectDetails = useRef(
		{} as Pick<IGetProjectResponseV2, 'id' | 'name'>,
	);
	const queryClient = useQueryClient();
	const createProjectV2 = useFeature('CreateProjectV2');
	const { enableCreateProjectV3: isCreateProjectV3 } = useCreateProjectV3();

	const me = queryClient.getQueryData(
		config.identity.get_user.queryKey,
	) as QueryResponse<IGetUserData>;
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const myEmail = me?.data.email;

	const filteredProjects =
		selectMyProjects && myEmail
			? allProjects.filter((project) => project.createdBy === myEmail)
			: allProjects;

	const projects = useMemo(
		() =>
			filteredProjects.map((p) => ({
				...p,
				nuOfEnvironments: p.environments?.length || 0,
			})),
		[filteredProjects],
	);

	const columns: ColumnDef<TProjects, any>[] = useMemo(() => {
		return projectsTableColumns({
			projectDetails,
			setOpenModal,
			isSmScreen: !!isSmScreen,
			createProjectV2,
			isCreateProjectV3,
		});
	}, [isSmScreen, createProjectV2, isCreateProjectV3]);

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	if (filteredProjects.length === 0)
		return (
			<CreateItemPlaceHolder
				customTitle={uDontHaveProjecttxt}
				isDisabled={isCreateButtonDisabled}
				showCreateButton={false}
			/>
		);

	return (
		<>
			<Table
				{...{
					columns,
					tableData: projects,
					dataTestId: 'projects-table',
					showPagination: projects.length > pageSize,
					headers: createProjectV2
						? headers
						: [
								'name',
								'Project ID',
								'Nu of Env',
								'Last updated',
								'',
							],
				}}
			/>
			{openModal && (
				<DeleteProject
					{...{
						openModal,
						setOpenModal,
						projectDetails: projectDetails.current,
					}}
				/>
			)}
		</>
	);
};
