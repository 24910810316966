import { Stack, Tooltip } from '@chakra-ui/react';
import { FC, useState } from 'react';
import {
	environmentVariable,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	useComponentHealthy,
	useSharedState,
} from '../../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import { Footer, XMCSButton } from '../../../shared-components';
import VariablesTable from '../tables/VariablesTable';
import CreateVariable from './CreateVariable';
import { t } from '@transifex/native';

const { create: createtxt } = environmentVariable;
const {
	errMsg4,
	errMsg1,
	createVariable: createVariabletxt,
	configuration: configurationtxt,
} = systemHealthStatus;

export const VariablesPageLegacy: FC = () => {
	const [variablesModalIsOpen, setVariablesModalIsOpen] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	const { isConfigurationHealthy, isError } = useComponentHealthy({});

	return (
		<>
			<Stack
				direction="row"
				justify="flex-end"
				mt={5}
				mb={isSmScreen ? 0 : 2}
			>
				{!isSmScreen && (
					<CreateVariableButton
						{...{
							isError,
							isConfigurationHealthy,
							setVariablesModalIsOpen,
						}}
					/>
				)}
			</Stack>
			<VariablesTable {...{ isConfigurationHealthy }} />
			{isSmScreen && (
				<Footer>
					<CreateVariableButton
						{...{
							isError,
							isConfigurationHealthy,
							setVariablesModalIsOpen,
						}}
					/>
				</Footer>
			)}
			{variablesModalIsOpen && (
				<CreateVariable
					openModal={variablesModalIsOpen}
					setOpenModal={setVariablesModalIsOpen}
				/>
			)}
		</>
	);
};

export const CreateVariableButton: FC<{
	isError: boolean;
	isConfigurationHealthy: boolean;
	setVariablesModalIsOpen: (value: boolean) => void;
}> = ({ isError, isConfigurationHealthy, setVariablesModalIsOpen }) => (
	<Tooltip
		label={
			isError
				? t(errMsg1)
				: t(errMsg4, {
						variableTxt: configurationtxt,
						variableTxt2: createVariabletxt,
					})
		}
		isDisabled={isConfigurationHealthy}
		shouldWrapChildren
	>
		<XMCSButton
			text={createtxt}
			onClick={() => setVariablesModalIsOpen(true)}
			isDisabled={!isConfigurationHealthy}
		/>
	</Tooltip>
);
