import { FC, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {
	app,
	connections,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';

import {
	useComponentHealthy,
	useProjectDetails,
	useValidateConnection,
} from '../../../../@xmcloud/hooks/';
import { getTabIndex } from '../../../../@xmcloud/utils/helpers';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';
import { useGetEnvironment } from '../../../services/environments';
import {
	HeaderStack,
	XMCSBreadCrumb,
	XMCSMenuButton,
	XMCSTabs,
} from '../../../shared-components';
import { environmentItems } from '../../../shared-components/breadcrumb/items';
import {
	commonEnvTabItems,
	EHEnvironementTabItems,
	envEHTabItemsLegacy,
	environementTabItemsLegacy,
	environementTabItems as environmentTabItems,
} from '../../../shared-components/tab/items';
import { useCreateProjectV3 } from '../../create-project/old-components/useCreateProjectV3';
import DeleteEnvironment from '../components/DeleteEnvironment';
import EnvironmentMenuItems from '../components/EnvironmentMenuItems';
import LinkEnvToOtherBranch from '../components/LinkEnvToOtherBranch';
import EditEnvironment from './EditEnvironment';

const { errorMsg5 } = systemHealthStatus;
const { noSourceControl: noSourceControltxt } = connections;

export const EnvironmentPage: FC = () => {
	const { environmentId, projectId, ...rest } = useParams<{
		environmentId: string;
		projectId: string;
		// Used for the updated route for V3
		'envType-environments': string;
	}>();

	const { pathname } = useLocation();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [linkToAnotherBranchModal, setLinkToAnotherBranchModal] =
		useState(false);
	const [enableIntegrationValidation, setEnableIntegrationValidation] =
		useState(false);

	const { data: environmentData, isLoading: isEnvironmentLoading } =
		useGetEnvironment({
			id: environmentId!,
		});

	const envTypeFromPath = rest['envType-environments']?.split('-')[0];
	const environmentName = environmentData?.data.name || '';
	const environment = environmentData?.data as IGetEnvironmentResponseV2;
	const environmentType = environment?.type;
	const { projectName, project } = useProjectDetails(projectId!);
	const projectData = project?.data;

	const { enableCreateProjectV3: isCreateProjectV3 } = useCreateProjectV3();

	const envType = environmentType
		? environmentType
		: envTypeFromPath || 'combined';

	type TabItemType = 'cm' | 'eh' | 'combined';

	const type = (isCreateProjectV3 ? envType : 'combined') as TabItemType;

	const tabItemsMap: Record<TabItemType, typeof commonEnvTabItems> = {
		cm: commonEnvTabItems,
		eh: EHEnvironementTabItems,
		combined:
			envType === 'eh' ? envEHTabItemsLegacy : environementTabItemsLegacy,
	};

	const tabItems = tabItemsMap[type] || environmentTabItems;
	const tabIndex = getTabIndex(pathname, envType, !isCreateProjectV3);

	const [deploymentCreateAndDeploy, isDeploymentLoading] =
		useDeploymentCreateAndDeploy();
	const sourceControlIntegrationId =
		projectData?.sourceControlIntegrationId || '';

	const {
		isValidIntegration,
		integrationIdValidationMsg,
		provider,
		isLoading: isValidationIntegrationLoading,
		isNotValidIntegration,
	} = useValidateConnection({
		sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId && enableIntegrationValidation,
	});
	const {
		isGithubProvider,
		isAdoProvider,
		isCurrentProviderHealthy,
		isLoading: isHealthStatusLoading,
	} = useComponentHealthy({ provider });

	const isLoading =
		isDeploymentLoading ||
		isEnvironmentLoading ||
		isValidationIntegrationLoading ||
		isHealthStatusLoading;

	const isProviderHealthy = isCurrentProviderHealthy && isValidIntegration;
	const integrationErrorMessage = isLoading
		? ''
		: !sourceControlIntegrationId
			? noSourceControltxt
			: integrationIdValidationMsg || errorMsg5;

	const breadCrumbItems = environmentItems(
		projectName,
		projectId!,
		envType as 'eh' | 'cm' | 'combined',
	);

	return (
		<>
			<XMCSBreadCrumb items={breadCrumbItems} />
			<HeaderStack title={environmentName} mb={2}>
				<XMCSMenuButton
					text={app.options}
					{...{
						'data-testid': 'menu-button',
						isLoading,
						onClick: () => setEnableIntegrationValidation(true),
					}}
				>
					<EnvironmentMenuItems
						{...{
							setOpenDeleteModal,
							setOpenEditModal,
							environmentId: environmentId!,
							projectId: projectId!,
							environmentType,
							deploymentCreateAndDeploy,
							host: environment?.host,
							setLinkToAnotherBranchModal,
							environment,
							isProviderHealthy,
							isAdoProvider,
							isGithubProvider,
							integrationErrorMessage,
							enableIntegrationValidation,
							isNotValidIntegration,
						}}
					/>
				</XMCSMenuButton>
			</HeaderStack>
			<XMCSTabs
				tabIndex={tabIndex}
				tabItems={tabItems}
				state={{ projectName, environmentName }}
				args={[projectId!, environmentId!, envType!]}
			/>
			{openEditModal && Boolean(environment) && (
				<EditEnvironment
					{...{
						openModal: openEditModal,
						setOpenModal: setOpenEditModal,
						environment,
					}}
				/>
			)}
			{openDeleteModal && Boolean(environment) && (
				<DeleteEnvironment
					{...{
						openModal: openDeleteModal,
						setOpenModal: setOpenDeleteModal,
						environmentDetails: environment,
					}}
				/>
			)}

			{linkToAnotherBranchModal && (
				<LinkEnvToOtherBranch
					openModal={linkToAnotherBranchModal}
					setOpenModal={setLinkToAnotherBranchModal}
					environmentId={environmentId}
					project={projectData}
					repositoryBranch={environment?.repositoryBranch}
				/>
			)}

			<Outlet />
		</>
	);
};
