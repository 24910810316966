import { TRANSFERFILTER } from '../../@xmcloud/types';
import { DeploymentFilter } from '../../@xmcloud/types';
import { TStages } from '../pages/project/deployment-logs/handlers';
import {
	monitoringBaseUrl,
	apiBaseUrl,
	identityBaseUrl,
	portalBaseUrl,
	featureFlagsBaseUrl,
	environment,
	platformInventoryUrl,
} from './envConfig';

const identityUrl = `${identityBaseUrl}/api/identity/v1`;
const projectsBaseUrl = apiBaseUrl + '/api/projects/v1';
const projectsBaseUrlV2 = apiBaseUrl + '/api/projects/v2';
const environmentsBaseUrl = apiBaseUrl + '/api/environments/v1';
const environmentsBaseUrlV2 = apiBaseUrl + '/api/environments/v2';
const environmentsLogsBaseUrl = monitoringBaseUrl + '/api/logs/v1';
const environmentsLogsBaseUrlV2 = monitoringBaseUrl + '/api/logs/v2';
const deploymentsBaseUrl = apiBaseUrl + '/api/deployments/v1';
const deploymentsBaseUrlV3 = apiBaseUrl + '/api/deployments/v3';
const transferBaseUrl = apiBaseUrl + '/api/transfers';
const transferDetailsBaseUrl = apiBaseUrl + '/api/transfer-details';
const transferConfigurationBaseUrl = apiBaseUrl + '/api/transfer-configuration';
const transferContentBaseUrl = apiBaseUrl + '/api/transfer-content';
const organizationsBaseUrl = apiBaseUrl + '/api/organizations/v1';
const gitBaseUrl = apiBaseUrl + '/api/sourcecontrol/v1';
const tenantsBaseUrl = apiBaseUrl + '/api/tenants/v1';
const clientsBaseUrl = apiBaseUrl + '/api/clients/v1';
const monitoringUrl = monitoringBaseUrl + '/api/Deployments/v1';
const statusUrl = apiBaseUrl + '/health/components';
const hostingBaseUrl = apiBaseUrl + '/api/hosting/v1';
const featureFlagsUrl = featureFlagsBaseUrl + '/api/featureflags/v1';
const allDeploymentsBaseUrl = apiBaseUrl + '/api/deployments/v1';
const gitBaseUrlV2 = apiBaseUrl + '/api/sourcecontrol/v2';
const githubApiBaseURL = 'https://api.github.com';
const adoVsspsBaseURL = 'https://app.vssps.visualstudio.com/_apis';
const adoApiBaseURL = 'https://dev.azure.com';
const platformInventoryURL = platformInventoryUrl + '/api/inventory/v1';

const PROJECT = 'project';
const ENVIRONMENT = 'environment';
const DEPLOYMENT = 'deployment';
const REPOSITORY = 'repository';
const TRANSFER = 'transfer';

const projects = {
	create_project: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${projectsBaseUrl}`,
	},
	create_project_v2: {
		scope: 'xmclouddeploy.projects:manageV2',
		url: `${projectsBaseUrlV2}`,
	},
	project: {
		scope: 'xmclouddeploy.projects:manage',
		url: (projectId: string) => `${projectsBaseUrl}/${projectId}`,
		queryKey: (projectId: string) => [PROJECT, projectId] as const,
	},
	project_v2: {
		scope: 'xmclouddeploy.projects:manage',
		url: (projectId: string) => `${projectsBaseUrlV2}/${projectId}`,
		queryKey: (projectId: string) => [PROJECT, projectId] as const,
	},
	get_projects_list: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${projectsBaseUrl}`,
		queryKey: [PROJECT, 'list'] as const,
	},
	get_projects_list_v2: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${projectsBaseUrlV2}`,
		queryKey: [PROJECT, 'list'] as const,
	},
	project_environments: {
		scope: 'xmclouddeploy.environments:manage',
		url: (projectId: string) =>
			`${projectsBaseUrl}/${projectId}/environments`,
		queryKey: (projectId: string) =>
			[PROJECT, 'environments-list', projectId] as const,
	},
	project_environments_v2: {
		scope: 'xmclouddeploy.environments:manage',
		url: (projectId: string) =>
			`${projectsBaseUrlV2}/${projectId}/environments`,
		queryKey: (projectId: string) =>
			[PROJECT, 'environments-list', projectId] as const,
	},
	projects_Limitation: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${projectsBaseUrl}/limitation`,
		queryKey: [PROJECT, 'limitation'] as const,
	},
	environments_Limitation: {
		scope: 'xmclouddeploy.environments:manage',
		url: (projectId: string) =>
			`${projectsBaseUrl}/${projectId}/environments/limitation`,
		queryKey: (projectId: string) =>
			[PROJECT, 'environments-limitation', projectId] as const,
	},
	validate_project: {
		scope: 'xmclouddeploy.environments:manage',
		url: `${projectsBaseUrl}/name/validate`,
	},
	validate_project_v2: {
		scope: 'xmclouddeploy.project:manage',
		url: (projectName: string) =>
			`${projectsBaseUrlV2}/validatename?name=${projectName}`,
		queryKey: (projectName: string) =>
			[PROJECT, 'name-validation', projectName] as const,
	},
	link_repository: {
		scope: 'xmclouddeploy.project:manage',
		url: (projectId: string) =>
			`${projectsBaseUrl}/${projectId}/repository`,
	},
};
const environments = {
	environment: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, environmentId] as const,
	},
	environment_v2: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrlV2}/${environmentId}`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, environmentId] as const,
	},
	environment_logs: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string, type?: string | null) =>
			`${environmentsLogsBaseUrl}/${environmentId}?type=${type}`,
		queryKey: (environmentId: string, type?: string | null) =>
			[ENVIRONMENT, 'logs-list-by-type', environmentId, type] as const,
	},
	environment_log_types: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsLogsBaseUrl}/${environmentId}/types`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, 'logs-types-list', environmentId] as const,
	},
	environment_log: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string, name: string) =>
			`${environmentsLogsBaseUrl}/${environmentId}/${name}`,
		queryKey: (environmentId: string, name: string) =>
			[ENVIRONMENT, 'log-detail', environmentId, name] as const,
	},
	environment_log_V2: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string, name: string) =>
			`${environmentsLogsBaseUrlV2}/${environmentId}/${name}`,
		queryKey: (environmentId: string, name: string) =>
			[ENVIRONMENT, 'log-detail', environmentId, name] as const,
	},
	environment_deployments: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/deployments`,
		queryKey: (environmentId?: string) =>
			[ENVIRONMENT, 'deployments', environmentId] as const,
	},
	environment_restart: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/restart`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, 'restart', environmentId] as const,
	},
	environment_deployment_promote: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string, deploymentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/promote/${deploymentId}`,
	},
	environment_variables: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/variables`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, 'variables-list', environmentId] as const,
	},
	environment_variable_upsert: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string, variableName: string) =>
			`${environmentsBaseUrl}/${environmentId}/variables/${variableName}`,
	},
	environment_variable_delete_by_target: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string, variableName: string, target: string) =>
			`${environmentsBaseUrlV2}/${environmentId}/variables/${variableName}/${target}`,
	},
	environment_all_limitation: {
		scope: 'xmclouddeploy.environments:manage',
		url: `${environmentsBaseUrl}/limitation`,
		queryKey: [ENVIRONMENT, 'limitation'] as const,
	},
	environment_authoring: {
		scope: 'xmclouddeploy.environments:manage',
		url: (host: string | undefined) =>
			`https://${host}/sitecore/api/authoring/graphql/v1`,
		queryKey: (
			query:
				| 'sites'
				| 'publish-status'
				| 'rendering-hosts-and-sites'
				| 'databases'
				| 'languages'
				| 'items',

			host?: string,
		) => [ENVIRONMENT, query, host] as const,
	},
	environment_edge_token: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/obtain-edge-token`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, 'edge-token', environmentId] as const,
	},
	environment_editing_secret: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/obtain-editing-secret`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, 'editing-secret', environmentId] as const,
	},
	environments_queryBy_projectType: {
		scope: 'xmclouddeploy.environments:querybyProjectType',
		url: (pageNumber: number, pageSize: number) =>
			`${environmentsBaseUrl}?PageNumber=${pageNumber}&PageSize=${pageSize}&projectTypes=cm&projectTypes=combined`,
		queryKey: (projectType1: string) =>
			[ENVIRONMENT, 'queryByProjectType', projectType1] as const,
	},
	environment_deployment_promote_v2: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string, deploymentId: string) =>
			`${environmentsBaseUrlV2}/${environmentId}/promote/${deploymentId}`,
	},
	environment_deployments_v2: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrlV2}/${environmentId}/deployments`,
		queryKey: (environmentId?: string) =>
			[ENVIRONMENT, 'deployments', environmentId] as const,
	},
	regenerate_contextid: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/regenerate-context`,
		queryKey: (environmentId: string) =>
			[ENVIRONMENT, 'contextid', environmentId] as const,
	},
	linkRepoToEnvironment: {
		scope: 'xmclouddeploy.environments:manage',
		url: (environmentId: string) =>
			`${environmentsBaseUrl}/${environmentId}/repository`,
	},
};
const deployments = {
	deployment: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (deploymentId: string) => `${deploymentsBaseUrl}/${deploymentId}`,
		queryKey: (deploymentId: string) => [DEPLOYMENT, deploymentId] as const,
	},
	deployment_artifacts: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (deploymentId: string) =>
			`${deploymentsBaseUrl}/${deploymentId}/artifacts`,
	},
	deployment_deploy: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (deploymentId: string) =>
			`${deploymentsBaseUrl}/${deploymentId}/deploy`,
	},
	cancel_deployment: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (deploymentId: string) =>
			`${deploymentsBaseUrl}/${deploymentId}/cancel`,
	},
	logs: {
		scope: 'xmclouddeploy.deployments:logs',
		url: (deploymentId: string) =>
			`${deploymentsBaseUrl}/${deploymentId}/cancel`,
	},
	stageLogs: {
		scope: 'xmclouddeploy.deployments:stageLogs',
		url: (deploymentId: string) =>
			`${deploymentsBaseUrl}/${deploymentId}/cancel`,
	},
	deploymentsByStatus: {
		scope: 'xmclouddeploy.deployments:status',
		url: `${allDeploymentsBaseUrl}/status`,
		queryKey: [DEPLOYMENT, 'all', 'status-count'] as const,
	},
	deployment_v3: {
		scope: 'xmclouddeploy.deployments:manage',
		url: (deploymentId: string) =>
			`${deploymentsBaseUrlV3}/${deploymentId}`,
		queryKey: (deploymentId: string) => [DEPLOYMENT, deploymentId] as const,
	},
	allDeployments_v3: {
		scope: 'xmclouddeploy.deployments:all',
		url: (
			pageNumber: number,
			pageSize: number,
			filter?: DeploymentFilter,
		) => {
			const isUser = filter === DeploymentFilter.user;
			const isSkippedQueued = filter === DeploymentFilter.skippedQueued;
			const currentFilter = isSkippedQueued
				? '&statuses=0&statuses=4'
				: `${
						isUser ? '&filter=user' : ''
					}&statuses=0&statuses=1&statuses=2&statuses=3`;
			return `${deploymentsBaseUrlV3}?pageNumber=${pageNumber}&pageSize=${pageSize}${currentFilter}`;
		},
		queryKey: (pageIndex: number, filter: DeploymentFilter) => {
			const isUser = filter === DeploymentFilter.user;
			const isSkippedQueued = filter === DeploymentFilter.skippedQueued;
			return [
				'all-deployments',
				...(isSkippedQueued
					? ['skipped-queued']
					: isUser
						? ['my-deployments']
						: ['all']),
				pageIndex,
			] as const;
		},
	},
};

const organisations = {
	create_organisation: {
		scope: 'xmclouddeploy.organizations:manage',
		url: `${organizationsBaseUrl}`,
	},
	organisation: {
		scope: 'xmclouddeploy.organizations:manage',
		url: (organisationId: string) =>
			`${organizationsBaseUrl}/${organisationId}`,
	},
};

const monitoring = {
	get_historycal_data: {
		scope: 'monitoring',
		url: (deploymentId: string) => `${monitoringUrl}/${deploymentId}`,
		queryKey: (deploymentId: string) =>
			['monitoring', deploymentId] as const,
	},
	signalr_connection: {
		scope: 'signalr.monitoring',
		url: `${monitoringBaseUrl}/deploymentHub`,
	},
	logs: {
		scope: 'signalr.monitoring:logs',
		url: (deploymentId: string) => `${monitoringUrl}/${deploymentId}/logs`,
		queryKey: (deploymentId: string) =>
			['deploymentLogs', deploymentId] as const,
	},
	stageLogs: {
		scope: 'signalr.monitoring:stageLogs',
		url: (deploymentId: string, stage: TStages) =>
			`${monitoringUrl}/${deploymentId}/logs?stage=${stage}`,
		queryKey: (deploymentId: string, stage: TStages) =>
			['stageLogs', deploymentId, stage] as const,
	},
};
const repository = {
	get_repo_branches: {
		scope: 'repository',
		url: (repositoryId: string) => `${gitBaseUrl}/${repositoryId}/branches`,
		queryKey: (repoId?: string, integrationId?: string) =>
			[REPOSITORY, 'branch-list', repoId, integrationId] as const,
	},
	integrate_create: {
		scope: 'repository',
		url: `${gitBaseUrl}/integration`,
	},
	integrate_state: {
		scope: 'repository',
		url: `${gitBaseUrl}/integration/state`,
	},
	get_repo_integrations: {
		scope: 'repository',
		url: `${gitBaseUrl}/integrations`,
		queryKey: [REPOSITORY, 'integrations'] as const,
	},
	get_repo_integration: {
		scope: 'repository',
		url: (id: string | undefined) => `${gitBaseUrl}/integration/${id}`,
		queryKey: (id: string | undefined) =>
			[REPOSITORY, 'integration', id] as const,
	},
	get_repo_templates: {
		scope: 'repository',
		url: `${gitBaseUrl}/templates`,
		queryKey: [REPOSITORY, 'templates'] as const,
	},
	get_repo_providers: {
		scope: 'repository',
		url: `${gitBaseUrl}/providers`,
		queryKey: [REPOSITORY, 'providers'] as const,
	},
	get_repositories: {
		scope: 'repository.github',
		url: `${gitBaseUrl}/repository`,
		queryKey: (integrationId?: string) =>
			[REPOSITORY, 'list', integrationId] as const,
	},
	validate_repo: {
		scope: 'repository.github',
		url: `${gitBaseUrl}/repository/validate`,
	},
	validate_integration: {
		scope: 'repository.github',
		url: `${gitBaseUrl}/integration/validate`,
	},
	get_repo_integrations_v2: {
		scope: 'repository',
		url: (pageNumber: number, pageSize: number) =>
			`${gitBaseUrlV2}/integrations?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		queryKey: (page: number) => [REPOSITORY, 'integrations', page] as const,
	},
	delete_integration: {
		scope: 'repository.github',
		url: (integrationId: string) =>
			`${gitBaseUrl}/integration/${integrationId}`,
	},
	validate_repo_name_V2: {
		scope: 'repository.github',
		url: (repoName: string, integrationId: string) =>
			`${gitBaseUrlV2}/repository/validate?RepositoryName=${repoName}&IntegrationId=${integrationId}`,
		queryKey: (repoName: string, integrationId: string) =>
			[REPOSITORY, 'validate-repoName', repoName, integrationId] as const,
	},
	validate_integration_V2: {
		scope: 'repository.github',
		url: (integrationId: string) =>
			`${gitBaseUrlV2}/integration/validate?IntegrationId=${integrationId}`,
		queryKey: (integrationId: string) =>
			[REPOSITORY, 'validate-integrationId', integrationId] as const,
	},
	source_control_hook_subscribe: {
		scope: 'hook-subscribe',
		url: `${gitBaseUrl}/hook/subscribe`,
	},
};

const tenants = {
	get_clients: {
		scope: 'tenants',
		url: `${tenantsBaseUrl}/clients`,
	},
	generate_token: {
		scope: 'tenants',
		url: (clientId: string) =>
			`${tenantsBaseUrl}/clients/${clientId}/token/generate`,
	},
	revoke_token: {
		scope: 'tenants',
		url: (clientId: string) =>
			`${tenantsBaseUrl}/clients/${clientId}/token/revoke`,
	},
};

const identity = {
	get_user: {
		scope: 'identity.user:read',
		url: `${identityUrl}/user`,
		queryKey: ['identity', 'user'] as const,
	},
	get_organization: {
		scope: 'identity.organization:update',
		url: `${identityUrl}/organization`,
		queryKey: ['identity', 'organization'] as const,
	},
};

const transfers = {
	allTransfers: {
		scope: 'xmcct.transfers:all',
		url: (pageNumber: number, pageSize: number, filter?: TRANSFERFILTER) =>
			`${transferBaseUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}${
				filter ? `&filter=${filter}` : ''
			}`,
		queryKey: (filter?: TRANSFERFILTER) => [TRANSFER, filter] as const,
	},
	transfer: {
		scope: 'xmcct.transfers:manage',
		url: (transferId: string) => `${transferBaseUrl}/${transferId}`,
		queryKey: (transferId: string) => [TRANSFER, transferId] as const,
	},
	transferDetails: {
		scope: 'xmcct.transfer-details:manage',
		url: (transferId: string) => `${transferDetailsBaseUrl}/${transferId}`,
		queryKey: (transferId: string) =>
			[TRANSFER, 'transfer-details', transferId] as const,
	},
	transferConfiguration: {
		scope: 'xmcct.transfer-configuration:manage',
		url: (transferId: string) =>
			`${transferConfigurationBaseUrl}/${transferId}`,
		queryKey: (transferId: string) =>
			[TRANSFER, 'transfer-configuration', transferId] as const,
	},
	transferContent: {
		scope: 'xmcct.transfer-content:manage',
		url: (transferId: string) => `${transferContentBaseUrl}/${transferId}`,
		queryKey: (transferId: string) =>
			[TRANSFER, 'transfer-content', transferId] as const,
	},
};
const environmentHostProtocol = 'https';

const externalLinks = {
	profile_manage: `${portalBaseUrl}/profile`,
	cm_instance: (environmentHost: string) =>
		`${environmentHostProtocol}://${environmentHost}`,
	xm_cloud_dashboard: (environmentHost: string) =>
		`${externalLinks.cm_instance(environmentHost)}/sitecore`,
};

const clients = {
	page_size: 10,
	create_edge: {
		scope: 'clients',
		url: `${clientsBaseUrl}/edge`,
	},
	create_environment_automation: {
		scope: 'clients',
		url: `${clientsBaseUrl}/cm`,
	},
	create_organization_automation: {
		scope: 'clients',
		url: `${clientsBaseUrl}/deploy`,
	},
	get_environment_list: {
		scope: 'clients',
		url: `${clientsBaseUrl}/environment`,
		queryKey: 'environment-client-list' as const,
	},
	get_organization_list: {
		scope: 'clients',
		url: `${clientsBaseUrl}/organization`,
		queryKey: 'organization-client-list' as const,
	},
	delete: {
		scope: 'clients',
		url: (id: string) => `${clientsBaseUrl}/${id}`,
	},
};

const hosting = {
	install: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${hostingBaseUrl}/install`,
	},
	get_installations_list: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${hostingBaseUrl}/installations`,
		queryKey: 'hosting-installations-list' as const,
	},
	create_project: {
		scope: 'xmclouddeploy.projects:manage',
		url: `${hostingBaseUrl}/project`,
	},
	get_projects_list: {
		scope: 'xmclouddeploy.projects:manage',
		url: (environmentId?: string) =>
			`${hostingBaseUrl}/project?environmentId=${environmentId}`,
		queryKey: (environmentId?: string) =>
			['hosting', 'projects-list', environmentId] as const,
	},
	get_state: {
		scope: 'xmclouddeploy.sourcecontrol:manage',
		url: `${hostingBaseUrl}/state`,
	},
	validate_installation: {
		scope: 'xmclouddeploy.projects:manage',
		url: (integrationId?: string) =>
			`${hostingBaseUrl}/validate?integrationId=${integrationId}`,
	},
	delete_project: {
		scope: 'xmclouddeploy.sourcecontrol:manage',
		url: (projectId: string, deleteFromProvider?: boolean) =>
			`${hostingBaseUrl}/project/${projectId}${
				deleteFromProvider
					? `?deleteFromProvider=${deleteFromProvider}`
					: ''
			}`,
	},
	delete_connection: {
		scope: 'xmclouddeploy.sourcecontrol:manage',
		url: (id: string) => `${hostingBaseUrl}/installation/${id}`,
	},
};

const toAzureFeatureLabel = (envName: string) => {
	switch (envName) {
		case 'dev':
			return 'dev';
		case 'qa':
			return 'qa';
		case 'staging':
			return 'staging';
		case 'pre-production':
		case 'preprod':
			return 'preprod';
		case 'production':
		case 'prod':
		default:
			return 'prod';
	}
};

const featureFlags = {
	scope: 'featureflags',
	url: `${featureFlagsUrl}/flags?key=xmcloud&labelfilter=${toAzureFeatureLabel(
		environment,
	)}`,
};

const accesToken = {
	get_source_control_access_token: {
		scope: 'source-control-access-token',
		url: (integrationId: string) =>
			`${gitBaseUrl}/accesstoken?IntegrationId=${integrationId}`,
		queryKey: (integrationId: string) =>
			['source-control-access-token', integrationId] as const,
	},
};

const sourceControl = {
	get_app_installation_github_repositories: {
		url: (page = 1, perPage: number) =>
			`${githubApiBaseURL}/installation/repositories?page=${page}&per_page=${perPage}`,
		queryKey: (integrationId: string) =>
			['github-app-installation-repositories', integrationId] as const,
	},
	get_organisation_github_repositories: {
		url: (org: string, page: number, perPage: number) =>
			`${githubApiBaseURL}/orgs/${org}/repos?page=${page}&per_page=${perPage}`,
		queryKey: (org: string) =>
			['github-organisation-repositories', org] as const,
	},
	get_user_github_repositories: {
		url: (page = 1, perPage: number) =>
			`${githubApiBaseURL}/user/repos?type=owner&page=${page}&per_page=${perPage}`,
		queryKey: (integrationId: string) =>
			['github-user-repositories', integrationId] as const,
	},
	get_github_repository: {
		url: (owner: string, repo: string) =>
			`${githubApiBaseURL}/repos/${owner}/${repo}`,
		queryKey: (owner: string, repo: string) =>
			['github-public-repository', owner, repo] as const,
	},
	get_github_repo_branches: {
		url: (owner: string, repo: string, page: number = 1, perPage = 10) =>
			`${githubApiBaseURL}/repos/${owner}/${repo}/branches?page=${page}&per_page=${perPage}`,
		queryKey: (owner: string, repo: string, repositoryNamespace: string) =>
			['github-repo-branches', owner, repo, repositoryNamespace] as const,
	},
	get_ado_profile: {
		url: `${adoVsspsBaseURL}/profile/profiles/me?api-version=7.0`,
		queryKey: (integrationId: string) =>
			['ado-profile', integrationId] as const,
	},
	get_ado_organizations: {
		url: (publicAlias: string) =>
			`${adoVsspsBaseURL}/accounts?memberId=${publicAlias}&api-version=7.0`,
		queryKey: (publicAlias: string) =>
			['ado-organization', publicAlias] as const,
	},
	get_ado_projects: {
		url: (organization: string) =>
			`${adoApiBaseURL}/${organization}/_apis/projects?api-version=7.0`,
		queryKey: (organization: string) =>
			['ado-projects', organization] as const,
	},
	get_ado_repositories: {
		url: (organization: string, project: string) =>
			`${adoApiBaseURL}/${organization}/${project}/_apis/git/repositories?api-version=7.0`,
		queryKey: (organization: string, project: string) =>
			['ado-repositories', organization, project] as const,
	},
	get_ado_repository: {
		url: (organization: string, project: string, repo: string) =>
			`${adoApiBaseURL}/${organization}/${project}/_apis/git/repositories/${repo}?api-version=7.0`,
		queryKey: (organization: string, project: string, repo: string) =>
			['ado-repository', organization, project, repo] as const,
	},
	get_ado_branches: {
		url: (organization: string, project: string, repositoryId: string) =>
			`${adoApiBaseURL}/${organization}/${project}/_apis/git/repositories/${repositoryId}/refs?filter=heads&api-version=7.0`,
		queryKey: (
			organization: string,
			project: string,
			repositoryId: string,
		) => ['ado-branches', organization, project, repositoryId] as const,
	},
};
const TENANT = 'tenant';
const platformInventory = {
	get_tenant: {
		url: (tenantId: string) =>
			`${platformInventoryURL}/tenants/${tenantId}`,
		queryKey: (tenantId: string) => [TENANT, tenantId] as const,
	},
};

const config = {
	projects,
	environments,
	transfers,
	deployments,
	organisations,
	monitoring,
	repository,
	tenants,
	clients,
	identity,
	externalLinks,
	statusUrl,
	hosting,
	featureFlags,
	accesToken,
	sourceControl,
	platformInventory,
};

export default config;
