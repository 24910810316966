import { useCreateProject } from '../../../../@xmcloud/hooks';
import { ESourceControl } from '../../../../@xmcloud/types';
import { OLDReviewFinishADO } from './OLDReviewFinishADO';
import { OLDReviewFinishGithub } from './OLDReviewFinishGithub';
import { OLDReviewFinishOwnCode } from './OLDReviewFinishOwnCode';

const { GitHub, ADO } = ESourceControl;

export const OLDReviewFinishMain: React.FC = () => {
	const {
		values: { provider, isByoc },
	} = useCreateProject();

	const isGithubProvider = provider === GitHub;
	const isAdoProvider = provider === ADO;

	if (!isByoc && isGithubProvider) {
		return <OLDReviewFinishGithub />;
	} else if (!isByoc && isAdoProvider) {
		return <OLDReviewFinishADO />;
	} else if (isByoc) {
		return <OLDReviewFinishOwnCode />;
	} else {
		return <></>;
	}
};
