import { Divider, HStack, Stack, Switch } from '@chakra-ui/react';
import {
	AdoRepository,
	EnvironmentName,
	EnvironmentVariables,
	FooterBtnGroups,
	GithubRepository,
	Options,
	RepositoryDetails,
	SetupProviderAccount,
} from '../components';
import {
	CREATE_EDITING_HOST,
	EH_ADO_ORGANIZATION,
	EH_ADO_PROJECT_NAME,
	EH_CONNECTION_NAME,
	EH_ENVIRONMENT_NAME,
	EH_ENVIRONMENT_VARIABLES,
	EH_PROVIDER,
	EH_REPO_BRANCH,
	EH_REPOSITORY,
	EH_REPOSITORY_ID,
	EH_SOURCE_CONTROL_INTEGRATION_ID,
	EnvironmentTypesEnum,
} from '../helpers';
import { ESourceControl } from '../../../../@xmcloud/types';
import { thirdStep } from '../../../../@xmcloud/core/messages/en';
import {
	useCreateProject,
	useValidateCmAndEhSteps,
} from '../../../../@xmcloud/hooks';
import { useValidateSourceControlIntegrationV2 } from '../../../services/repositories';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { HeadingLg, Text } from '../../../shared-components';
import { EnvVars } from '../../../features/custom-environment-variables';
import { useEffect } from 'react';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';

const { info1, nameEhEnv, ehPageTitle, ehPageTitleOptional, ehSubTitle } =
	thirdStep;

const { EH } = EnvironmentTypesEnum;
const { ADO, GitHub } = ESourceControl;

const repoLookup = {
	[GitHub]: (props: any) => <GithubRepository {...props} />,
	[ADO]: (props: any) => <AdoRepository {...props} />,
	'': () => <></>,
};

export const SetupEditingHost: React.FC = () => {
	const {
		values,
		errors,
		handleChange,
		setFieldTouched,
		touched,
		prev,
		setValues,
		setErrors,
		setFieldValue,
		setTouched,
		isByoc,
	} = useCreateProject();

	const sourceControlIntegrationId = values[EH_SOURCE_CONTROL_INTEGRATION_ID];

	const {
		isFetching: isSourceControlIntegrationLoading,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
	} = useValidateSourceControlIntegrationV2({
		integrationId: sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId,
	});
	const shouldCallAccessTokenApi =
		isIntegrationIdValidationValid && !isSourceControlIntegrationLoading;

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(
			sourceControlIntegrationId,
			shouldCallAccessTokenApi,
		);
	const accessToken = accessTokenData?.data.accessToken || '';
	const isLoading = isSourceControlIntegrationLoading || isLoadingAccessToken;

	const { handleNext, isLoading: validationIsLoading } =
		useValidateCmAndEhSteps({
			isIntegrationIdValidationValid,
			integrationValidationErrorMsg,
			nameSpace: EH,
		});

	const CurrentRepository = repoLookup[values[EH_PROVIDER]];

	useEffect(() => {
		if (isByoc && !values.ehSourceControlIntegrationId) {
			const {
				cmProvider,
				cmSourceControlIntegrationId,
				cmSourceControlIntegrationName,
				cmRepositoryBranch,
				cmRepository,
				cmRepositoryId,
				cmAdoOrganization,
				cmAdoProjectName,
			} = values;
			setValues((prev: FormikValues) => ({
				...prev,
				[EH_PROVIDER]: cmProvider,
				[EH_SOURCE_CONTROL_INTEGRATION_ID]:
					cmSourceControlIntegrationId,
				[EH_CONNECTION_NAME]: cmSourceControlIntegrationName,
				[EH_REPOSITORY]: cmRepository,
				[EH_REPOSITORY_ID]: cmRepositoryId,
				[EH_ADO_ORGANIZATION]: cmAdoOrganization,
				[EH_ADO_PROJECT_NAME]: cmAdoProjectName,
				[EH_REPO_BRANCH]: cmRepositoryBranch,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isByoc]);

	if (!values.createEditingHostForByoc && isByoc) {
		return (
			<Stack gap="6" pb="10">
				<HeadingLg text={ehPageTitleOptional} />
				<Text text={ehSubTitle} />
				<HStack>
					<Switch
						isChecked={values.createEditingHostForByoc}
						onChange={(e) =>
							setFieldValue(CREATE_EDITING_HOST, e.target.checked)
						}
					/>
					<Text color="black" text={ehPageTitle} />
				</HStack>
				<Divider />
				<FooterBtnGroups
					{...{
						onNextClick: handleNext,
						onPreviousClick: prev,
						isLoading: validationIsLoading || isLoading,
						py: 0,
					}}
				/>
			</Stack>
		);
	}

	return (
		<Stack gap="7" pb="10">
			<HeadingLg text={isByoc ? ehPageTitleOptional : ehPageTitle} />
			<Text text={ehSubTitle} />
			{isByoc && (
				<HStack>
					<Switch
						isChecked={values.createEditingHostForByoc}
						onChange={(e) =>
							setFieldValue(CREATE_EDITING_HOST, e.target.checked)
						}
					/>
					<Text color="black" text={ehPageTitle} />
				</HStack>
			)}
			<EnvironmentName
				{...{
					values,
					errors,
					touched,
					handleChange,
					setFieldTouched,
					helperText: info1,
					label: nameEhEnv,
					environmentNameSpace: EH_ENVIRONMENT_NAME,
				}}
			/>
			<Divider />
			{isByoc && (
				<SetupProviderAccount
					{...{
						values,
						errors,
						touched,
						setValues,
						setTouched,
						setErrors,
						setFieldTouched,
						isIntegrationIdValidationValid,
						integrationValidationErrorMsg,
						isSourceControlIntegrationLoading,
						nameSpace: EH,
					}}
				/>
			)}
			{isByoc && (
				<CurrentRepository
					{...{
						isSourceControlIntegrationLoading: isLoading,
						nameSpace: EH,
						accessToken,
						values,
						errors,
						touched,
						setValues,
						setFieldTouched,
						setFieldValue,
						showRepoCombobox: isByoc,
						isByoc,
						handleChange,
					}}
				/>
			)}
			{!isByoc && <RepositoryDetails />}
			<Divider />
			<Options
				{...{
					nameSpace: EH,
					values,
					setFieldValue,
					showSLA: false,
				}}
			/>
			<Divider />
			<EnvVars
				{...{
					isExistingProject: false,
					initialVariables: values.ehEnvironmentVariables,
					onVariablesChange: (variables) => {
						setFieldValue(EH_ENVIRONMENT_VARIABLES, variables);
					},
				}}
			>
				<EnvironmentVariables
					variables={values.ehEnvironmentVariables}
				/>
			</EnvVars>
			<Divider />
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: prev,
					isLoading: validationIsLoading || isLoading,
					py: 0,
				}}
			/>
		</Stack>
	);
};
