import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
	app,
	projects,
	repository as repo,
} from '../../../@xmcloud/core/messages/en';
import { useProjectDetails } from '../../../@xmcloud/hooks';
import { CircularLoading, TextBox } from '../../shared-components';
import { getReadableDate } from '../../../@xmcloud/utils/dateUtils';
import { useFeature } from '../../feature-flag/features';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

const { id: idtxt, region: regiontxt, type: typetxt } = app;
const {
	account: accounttxt,
	name: nametxt,
	repositoryLinkedDate: repositoryLinkedDatetxt,
} = repo;
const { webApp, contentService } = projects;

export const ProjectDetailsPage: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const { project, isLoading } = useProjectDetails(projectId!);
	const projectData = project?.data ?? {};
	const createProjectV2 = useFeature('CreateProjectV2');

	const {
		id,
		region,
		repository,
		sourceControlIntegrationName,
		repositoryLinkedAt,
	} = projectData;

	const projectType = projectData?.type || '';
	const isEH = 'eh' === projectType;

	const details = useMemo(
		() =>
			[
				{
					title: typetxt, // Project Type
					subTitle: isEH ? webApp : contentService,
					visible: createProjectV2,
				},
				{ title: idtxt, subTitle: id, visible: true }, // Project ID
				{ title: regiontxt, subTitle: region, visible: true }, // Region
				{
					title: accounttxt, // Repository Account Name
					subTitle: sourceControlIntegrationName || '-',
					visible: true,
				},
				{
					title: nametxt, // Repository Name
					subTitle: repository || '-',
					visible: true,
				},
				{
					title: repositoryLinkedDatetxt, // Repository Linked Date
					subTitle:
						(repositoryLinkedAt &&
							getReadableDate(repositoryLinkedAt, 'PPp')) ||
						'-',
					visible: true,
				},
			].filter(({ visible }) => visible),
		[
			createProjectV2,
			id,
			isEH,
			repository,
			repositoryLinkedAt,
			sourceControlIntegrationName,
			region,
		],
	);

	if (isEmpty(projectData) || isLoading) return <CircularLoading />;

	return (
		<Stack spacing={5} mt={5} pl={5}>
			{details.map(({ title, subTitle }) => (
				<TextBox {...{ title, subTitle }} key={title} />
			))}
		</Stack>
	);
};
