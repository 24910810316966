import { useQueryClient } from 'react-query';
import config from '../../app/config/config';
import { IGetEnvironmentResponseV2 } from '../../app/models/environmentModel';
import { useGetEnvironment } from '../../app/services/environments';
import { QueryResponse } from '../types';

const {
	environments: { environment: environmentConfig },
} = config;

export function useEnvironmentDetails(
	environmentId: string,
	shouldEnabled?: boolean,
) {
	const queryClient = useQueryClient();
	const environmentQueryData = queryClient.getQueryData(
		environmentConfig.queryKey(environmentId),
	) as QueryResponse<IGetEnvironmentResponseV2>;

	const { data: environmentData, isLoading } = useGetEnvironment({
		id: environmentId,
		enabled: shouldEnabled ? true : !Boolean(environmentQueryData?.data),
	});

	const environmentName =
		environmentQueryData?.data.name || environmentData?.data.name;
	const environment = environmentQueryData?.data || environmentData?.data;
	const environmentType = environment?.type;

	return { environmentName, environment, isLoading, environmentType };
}
