import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Stack,
	ModalHeader,
} from '@chakra-ui/react';
import { app } from '../../../../@xmcloud/core/messages/en';
import { HeadingMd, XMCSButton } from '../../../shared-components';
import styles from '../../../../styles/scrollbar.module.css';

const { cancel: canceltxt } = app;

interface Props {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

export const MainModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="full"
			scrollBehavior="inside"
		>
			<ModalOverlay>
				<ModalContent>
					<ModalHeader display="flex" flexDirection="row">
						<Stack w="full">
							<HeadingMd text="Create project" />
						</Stack>
						<ModalCloseButton
							as={() => (
								<XMCSButton
									text={canceltxt}
									variant="outline"
									onClick={onClose}
								/>
							)}
						/>
					</ModalHeader>
					<ModalBody className={styles['custom-scrollbar']}>
						{children}
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};
