import { Stack, Tooltip, Wrap } from '@chakra-ui/react';
import {
	commonCw,
	firstStep,
	projects,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	HeadingLg,
	InputFormControl,
	AlertStatus,
	XMCSButton,
} from '../../../shared-components';
import {
	useComponentHealthy,
	useCreateProject,
} from '../../../../@xmcloud/hooks';
import { useGetValidateProjectName } from '../../../services/projects';
import { PROJECT_NAME } from '../helpers';

const { continue: continuetxt } = commonCw;
const { title: titletxt } = firstStep;
const { errMsg1, errMsg2 } = systemHealthStatus;
const { nameTitle, requiredName } = projects;

export const OLDProjectName: React.FC = () => {
	const {
		values,
		errors,
		handleChange,
		next,
		setFieldError,
		setFieldTouched,
		touched,
	} = useCreateProject();
	const {
		isBlockersHealthy,
		isLoading: isComponentHealthLoading,
		isError,
	} = useComponentHealthy({});

	const {
		refetch,
		isLoading: isValidationLoading,
		isFetching,
	} = useGetValidateProjectName(values.name);

	async function handleNext() {
		if (!values.name) {
			setFieldTouched(PROJECT_NAME, true);
			setFieldError(PROJECT_NAME, requiredName);
			return;
		}
		if (errors.name) {
			setFieldTouched(PROJECT_NAME, true);
			return;
		}

		const { data } = await refetch();
		const isValid = data?.data.isValid;
		const message = data?.data.message as string;
		if (!isValid) {
			setFieldError(PROJECT_NAME, message);
			return;
		}
		next();
	}

	const isLoading = isComponentHealthLoading || isValidationLoading;
	const showErrorAlert = !isBlockersHealthy && !isComponentHealthLoading;

	return (
		<Stack gap={5}>
			<HeadingLg text={titletxt} />
			<InputFormControl
				{...{
					isInvalid: Boolean(errors.name && touched.name),
					label: nameTitle,
					name: PROJECT_NAME,
					value: values.name,
					onChange: handleChange,
					error: errors.name,
					onFocus: () => setFieldTouched(PROJECT_NAME, true),
					maxW: 'sm',
					isRequired: true,
				}}
			/>
			<Wrap py="1">
				<Tooltip
					data-testid="tooltip-continue-btn"
					label={errMsg2}
					shouldWrapChildren
					isDisabled={isBlockersHealthy || isComponentHealthLoading}
				>
					<XMCSButton
						text={continuetxt}
						onClick={handleNext}
						alignSelf="start"
						size="md"
						isLoading={isLoading || isFetching}
						isDisabled={!isBlockersHealthy}
					/>
				</Tooltip>
			</Wrap>
			{showErrorAlert && (
				<AlertStatus
					description={isError ? errMsg1 : errMsg2}
					status="error"
					maxW="sm"
					data-testid="bloker-error-alert"
				/>
			)}
		</Stack>
	);
};
