import { FormikErrors, FormikTouched } from 'formik';
import {
	commonCw,
	fifthStep,
	firstStep,
} from '../../../@xmcloud/core/messages/en';
import { FormikValues } from '../../../@xmcloud/hooks/context/CreateProjectContext';
import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../@xmcloud/utils/helpers';

export const REPOSITORY = 'repository';
export const SOURCE_CONTROL_INTEGRATION_ID = 'sourceControlIntegrationId';
export const CM_SOURCE_CONTROL_INTEGRATION_ID = 'cmSourceControlIntegrationId';
export const EH_SOURCE_CONTROL_INTEGRATION_ID = 'ehSourceControlIntegrationId';

export const PROVIDER = 'provider';
export const CM_PROVIDER = 'cmProvider';
export const EH_PROVIDER = 'ehProvider';

export const IS_SECOND_STEP_REQUIRED = 'isSecondStepRequired';

export const CONNECTION_NAME = 'sourceControlIntegrationName';
export const CM_CONNECTION_NAME = 'cmSourceControlIntegrationName';
export const EH_CONNECTION_NAME = 'ehSourceControlIntegrationName';

export const REPO_BRANCH = 'repositoryBranch';
export const CM_REPO_BRANCH = 'cmRepositoryBranch';
export const EH_REPO_BRANCH = 'ehRepositoryBranch';

export const PROJECT_NAME = 'name';

export const ENVIRONMENT_NAME = 'environmentName';

export const TENANT_TYPE = 'tenantType';
export const DEPLOY_ON_COMMIT = 'deployOnCommit';
export const LINK_ENV_TO_DEFAULT_BRANCH = 'linkEnvironmentToDefaultBranch';
export const ACCOUNT_TYPE = 'accountType';
export const ADO_PROJECT_NAME = 'adoProjectName';
export const ADO_ORGANIZATION = 'adoOrganization';
export const REPOSITORY_ID = 'repositoryId';
export const PROJECT_TYPE = 'projectType';
export const REPOSITORY_RELATIVE_PATH = 'repositoryRelativePath';
export const CM_ENVIRONMENT_ID = 'cmEnvironmentId';
export const IS_BYOC = 'isByoc';
export const NAME = 'name';
export const ACCOUNT = 'account';
export const PROVIDER_NAME = 'providerName';
export const ENVIRONMENTS = 'environments';
export const HAS_ACCOUNT = 'hasAccount';
export const TEMPLATE = 'template';
export const TEMPLATE_OWNER = 'templateOwner';

export const CM_REPOSITORY = 'cmRepository';
export const EH_REPOSITORY = 'ehRepository';

export const CM_REPOSITORY_ID = 'cmRepositoryId';
export const EH_REPOSITORY_ID = 'ehRepositoryId';

export const CM_ADO_PROJECT_NAME = 'cmAdoProjectName';
export const EH_ADO_PROJECT_NAME = 'ehAdoProjectName';

export const CM_ADO_ORGANIZATION = 'cmAdoOrganization';
export const EH_ADO_ORGANIZATION = 'ehAdoOrganization';

export const CM_ENVIRONMENT_NAME = 'cmEnvironmentName';
export const EH_ENVIRONMENT_NAME = 'ehEnvironmentName';

export const CM_REPOSITORY_RELATIVE_PATH = 'cmRepositoryRelativePath';
export const EH_REPOSITORY_RELATIVE_PATH = 'ehRepositoryRelativePath';

export const CM_DEPLOY_ON_COMMIT = 'cmDeployOnCommit';
export const EH_DEPLOY_ON_COMMIT = 'ehDeployOnCommit';

export const AVAILABLE_CM_ENVIRONMENT_NAME = 'availableCmEnvironmentName';

export const COMBINED_PROJECT = 'combined';
export const CREATE_EDITING_HOST = 'createEditingHostForByoc';

export const CM_ENVIRONMENT_VARIABLES = 'cmEnvironmentVariables';
export const EH_ENVIRONMENT_VARIABLES = 'ehEnvironmentVariables';

const { setupRepo, authEnv, reviewFinish, chooseSourceControls } = commonCw;
const { title: nameYourProject } = firstStep;
const { info1: adoCreateRepo, createGithubRepoInfo } = fifthStep;

const commonSteps = [
	{ title: nameYourProject, index: 1 },
	{ title: chooseSourceControls, index: 2 },
	{ title: setupRepo, index: 3 },
];

export const steps = [
	...commonSteps,
	{ title: authEnv, index: 4 },
	{ title: reviewFinish, index: 5 },
];

export const createProjectSteps = [
	{ title: 'Set up project', index: 1 },
	{ title: 'Set up CM environment', index: 2 },
	{ title: 'Set up Editing Host', index: 3 },
	{ title: 'Review and deploy', index: 4 },
];

export type TOptions = Array<{
	value: string | boolean | number | null;
	label: string;
	id?: string | number;
}>;

export enum TemplateInNumberEnum {
	COMBINED = 1,
	EH = 2,
	CM = 3,
}

export enum EnvironmentTypesEnum {
	CM = 'cm', // standalone CM
	EH = 'eh', // front-end
}

export enum EnvironmentTypesInNumberEnum {
	EH = 1,
	CM = 2,
}

type ValueOf<T> = T[keyof T];

export type TProjectName = 'name';

export type TEnvironmentName = ValueOf<typeof environmentNameSpaces>;

export type TDeployOnCommit = ValueOf<typeof deployOnCommitNamespaces>;

export type TRepository = ValueOf<typeof repositoryNameSpaces>;

export type TRepositoryId = ValueOf<typeof repositoryIdNamespaces>;

export type TRepositoryBranch = ValueOf<typeof repositoryBranchNamespaces>;

export type TADOProjectName = ValueOf<typeof adoProjectNamespaces>;

export type TADOOrganization = ValueOf<typeof adoOrganizationNamespaces>;

export type TRepositoryRelativePath = ValueOf<
	typeof repositoryRelativePathNamespaces
>;
export type TConnectionName = ValueOf<typeof connectionNameSpaces>;

export type TProvider = ValueOf<typeof providerNameSpaces>;

export type TSourceControlIntegrationId = ValueOf<
	typeof sourceControlIntegrationIdNameSpaces
>;

const { EH, CM } = EnvironmentTypesEnum;

export const providerNameSpaces = {
	[CM]: CM_PROVIDER,
	[EH]: EH_PROVIDER,
	default: PROVIDER,
} as const;
export const connectionNameSpaces = {
	[CM]: CM_CONNECTION_NAME,
	[EH]: EH_CONNECTION_NAME,
	default: CONNECTION_NAME,
} as const;
export const sourceControlIntegrationIdNameSpaces = {
	[CM]: CM_SOURCE_CONTROL_INTEGRATION_ID,
	[EH]: EH_SOURCE_CONTROL_INTEGRATION_ID,
	default: SOURCE_CONTROL_INTEGRATION_ID,
} as const;

export const repositoryNameSpaces = {
	[CM]: CM_REPOSITORY,
	[EH]: EH_REPOSITORY,
	default: REPOSITORY,
} as const;

export const repositoryIdNamespaces = {
	[CM]: CM_REPOSITORY_ID,
	[EH]: EH_REPOSITORY_ID,
	default: REPOSITORY_ID,
} as const;

export const adoOrganizationNamespaces = {
	[CM]: CM_ADO_ORGANIZATION,
	[EH]: EH_ADO_ORGANIZATION,
	default: ADO_ORGANIZATION,
} as const;

export const adoProjectNamespaces = {
	[CM]: CM_ADO_PROJECT_NAME,
	[EH]: EH_ADO_PROJECT_NAME,
	default: ADO_PROJECT_NAME,
} as const;

export const repositoryBranchNamespaces = {
	[CM]: CM_REPO_BRANCH,
	[EH]: EH_REPO_BRANCH,
	default: REPO_BRANCH,
} as const;

export const repositoryRelativePathNamespaces = {
	[CM]: CM_REPOSITORY_RELATIVE_PATH,
	[EH]: EH_REPOSITORY_RELATIVE_PATH,
	default: REPOSITORY_RELATIVE_PATH,
} as const;

export const environmentNameSpaces = {
	[CM]: CM_ENVIRONMENT_NAME,
	[EH]: EH_ENVIRONMENT_NAME,
	default: ENVIRONMENT_NAME,
} as const;

export const deployOnCommitNamespaces = {
	[CM]: CM_DEPLOY_ON_COMMIT,
	[EH]: EH_DEPLOY_ON_COMMIT,
	default: DEPLOY_ON_COMMIT,
} as const;

export const resetCmConnectionLevelFields = {
	[CM_SOURCE_CONTROL_INTEGRATION_ID]: '',
	[CM_CONNECTION_NAME]: '',
	[CM_REPOSITORY]: '',
	[CM_REPOSITORY_ID]: '',
	[CM_ADO_ORGANIZATION]: '',
	[CM_ADO_PROJECT_NAME]: '',
	[CM_REPO_BRANCH]: '',
};

export const resetEhConnectionLevelFields = {
	[EH_SOURCE_CONTROL_INTEGRATION_ID]: '',
	[EH_CONNECTION_NAME]: '',
	[EH_REPOSITORY]: '',
	[EH_REPOSITORY_ID]: '',
	[EH_ADO_ORGANIZATION]: '',
	[EH_ADO_PROJECT_NAME]: '',
	[EH_REPO_BRANCH]: '',
};

export const isEhEnvironment = (type: EnvironmentTypesEnum) => EH === type;
export const isCmEnvironment = (type: string) => CM === type;

export type TFormikCommonProps = {
	values: FormikValues;
	errors: FormikErrors<FormikValues>;
	touched: FormikTouched<FormikValues>;
};

const { IDLE, LOADING, ERROR, SUCCESS } = ResponseStatusEnum;

export const getProcessStatus = (
	...statuses: ResponseStatusTypes[]
): ResponseStatusTypes =>
	statuses.some((s) => s === ERROR)
		? ERROR
		: statuses.some((s) => s === LOADING)
			? LOADING
			: statuses.every((s) => s === SUCCESS)
				? SUCCESS
				: IDLE;

export const handleStatusText = (
	component: string,
	isCreateRepo = false,
	isAdo = false,
) => ({
	[IDLE]: `${component} creation is in queue.`,
	[LOADING]: !isCreateRepo
		? `${component} creation is in progress.`
		: isAdo
			? adoCreateRepo
			: createGithubRepoInfo,
	[ERROR]: `${component} creation is failed.`,
	[SUCCESS]: `${component} creation is successfully completed.`,
});
