import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useRef,
} from 'react';
import { createProjectValidationSchema } from '../../yup-validations';
import { FormikErrors, FormikState, FormikTouched, useFormik } from 'formik';
import { handlePush } from '../../utils/helpers';
import {
	ReviewFinishMain,
	SetupCmEnvironment,
	SetupEditingHost,
	SetupProject,
} from '../../../app/pages/create-project/steps';
import { goTo } from '../../core/routes/paths';
import {
	EAccountType,
	ESourceControl,
	SourceControlType,
	TenantType,
} from '../../types';
import { useSteps } from '@chakra-ui/react';
import { OLDProjectName } from '../../../app/pages/create-project/old-components/OLDProjectName';
import { OLDSetupRepository } from '../../../app/pages/create-project/old-components/OLDSetupRepository';
import { OLDAuthoringEnvironment } from '../../../app/pages/create-project/old-components/OLDAuthoringEnvironment';
import { OLDReviewFinishMain } from '../../../app/pages/create-project/old-components/OLDReviewFinishMain';
import SourceControls from '../../../app/pages/create-project/old-components/SourceControls';
import { EnvVariable } from '../../../app/features/custom-environment-variables';
import { useCreateProjectV3 } from '../../../app/pages/create-project/old-components/useCreateProjectV3';

const { createProjectTemplate, createProject } = goTo;

export type FormikValues = {
	name: string;
	provider: SourceControlType;
	ehProvider: SourceControlType;
	cmProvider: SourceControlType;
	sourceControlIntegrationId: string;
	cmSourceControlIntegrationId: string;
	ehSourceControlIntegrationId: string;
	cmSourceControlIntegrationName: string;
	ehSourceControlIntegrationName: string;
	sourceControlIntegrationName: string;
	repository: string;
	cmRepository: string;
	ehRepository: string;
	repositoryId: string;
	cmRepositoryId: string;
	ehRepositoryId: string;
	environmentName: string;
	deployOnCommit: boolean;
	tenantType: TenantType;
	linkEnvironmentToDefaultBranch: boolean;
	repositoryBranch: string;
	accountType: EAccountType | null;
	adoOrganization: string;
	adoProjectName: string;
	repositoryRelativePath: string;
	cmEnvironmentId: string;
	isByoc: boolean;
	cmRepositoryBranch: string;
	ehRepositoryBranch: string;
	cmAdoProjectName: string;
	ehAdoProjectName: string;
	cmAdoOrganization: string;
	ehAdoOrganization: string;
	cmEnvironmentName: string;
	ehEnvironmentName: string;
	cmRepositoryRelativePath: string;
	ehRepositoryRelativePath: string;
	cmDeployOnCommit: boolean;
	ehDeployOnCommit: boolean;
	availableCmEnvironmentName: string;
	template: string;
	templateOwner: string;
	cmEnvironmentVariables: EnvVariable[];
	ehEnvironmentVariables: EnvVariable[];
	createEditingHostForByoc: boolean;
	repositoryOwner: string;
};

type TPrev = (a: FormikValues) => FormikValues;

type CreateProjectContextType = {
	prev: () => void;
	next: () => void;
	errors: FormikErrors<FormikValues>;
	values: FormikValues;
	handleChange: any;
	formikHandleSubmit: any;
	step: ReactNode;
	isLastStep: boolean;
	setFieldError: (field: string, message: string) => void;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: any;
	touched: FormikTouched<FormikValues>;
	resetForm: (s?: Partial<FormikState<FormikValues>> | undefined) => void;
	setValues:
		| ((
				values: React.SetStateAction<FormikValues>,
				shouldValidate?: boolean | undefined,
		  ) => Promise<void> | Promise<FormikErrors<FormikValues>>)
		| ((prev: TPrev) => void);
	setTouched: any;
	setErrors: (errors: FormikErrors<FormikValues>) => void;
	activeStep: number;
	setActiveStep: any;
	isADOProvider: boolean;
	isByoc: boolean;
	isGithubProvider: boolean;
	isSingleEnvironmentCreation: boolean;
};

export const CreateProjectContext = createContext<CreateProjectContextType>(
	{} as CreateProjectContextType,
);

const { ADO, GitHub } = ESourceControl;

const initialValues: FormikValues = {
	name: '',
	provider: '',
	ehProvider: GitHub,
	cmProvider: GitHub,
	sourceControlIntegrationName: '',
	cmSourceControlIntegrationName: '',
	ehSourceControlIntegrationName: '',
	sourceControlIntegrationId: '',
	cmSourceControlIntegrationId: '',
	ehSourceControlIntegrationId: '',
	repository: '',
	cmRepository: '',
	ehRepository: '',
	repositoryId: '',
	cmRepositoryId: '',
	ehRepositoryId: '',
	environmentName: '',
	cmEnvironmentName: '',
	ehEnvironmentName: '',
	repositoryBranch: '',
	cmRepositoryBranch: '',
	ehRepositoryBranch: '',
	adoProjectName: '',
	cmAdoProjectName: '',
	ehAdoProjectName: '',
	adoOrganization: '',
	cmAdoOrganization: '',
	ehAdoOrganization: '',
	repositoryRelativePath: './src',
	cmRepositoryRelativePath: './authoring',
	ehRepositoryRelativePath: './headapps/nextjs-starter',
	deployOnCommit: false,
	cmDeployOnCommit: false,
	ehDeployOnCommit: false,
	cmEnvironmentVariables: [],
	ehEnvironmentVariables: [],
	availableCmEnvironmentName: '',
	template: '',
	templateOwner: '',
	tenantType: 0,
	accountType: null,
	linkEnvironmentToDefaultBranch: true,
	cmEnvironmentId: '',
	isByoc: false,
	createEditingHostForByoc: false,
	repositoryOwner: '',
};

const newSteps = [
	<></>,
	<SetupProject />,
	<SetupCmEnvironment />,
	<SetupEditingHost />,
	<ReviewFinishMain />,
];
const oldSteps = [
	<></>,
	<OLDProjectName />,
	<SourceControls />,
	<OLDSetupRepository />,
	<OLDAuthoringEnvironment />,
	<OLDReviewFinishMain />,
];

const CreateProjectProvider = ({ children }: { children: ReactNode }) => {
	const providers = useRef({
		isAdoProvider: false,
		isCmAdoProvider: false,
		isEhAdoProvider: false,
	});
	const isByoc = useRef(false);

	const { enableCreateProjectV3 } = useCreateProjectV3();

	const validationSchema = createProjectValidationSchema({
		providers: providers.current,
		isByoc: isByoc.current,
		enableCreateProjectV3,
	});

	const {
		errors,
		values,
		handleChange,
		setFieldError,
		setFieldValue,
		setFieldTouched,
		setValues,
		touched,
		handleSubmit: formikHandleSubmit,
		resetForm,
		setTouched,
		setErrors,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: false,
		onSubmit: (values: FormikValues) => {},
	});

	providers.current = {
		isAdoProvider: values.provider === ADO,
		isCmAdoProvider: values.cmProvider === ADO,
		isEhAdoProvider: values.ehProvider === ADO,
	};
	isByoc.current = values.isByoc;

	const steps = enableCreateProjectV3 ? newSteps : oldSteps;

	const { activeStep, setActiveStep } = useSteps({
		index: 1,
		count: steps.length,
	});

	const isLastStep = activeStep >= steps.length - 1;
	const isFirstStep = activeStep <= 1;

	const next = useCallback(() => {
		setActiveStep((prev: number) => {
			if (prev >= steps.length - 1) return prev;
			return prev + 1;
		});
		if (isLastStep) return;
		const currentRoute = enableCreateProjectV3
			? createProject
			: createProjectTemplate;

		handlePush(currentRoute(activeStep + 1));
	}, [
		activeStep,
		enableCreateProjectV3,
		isLastStep,
		setActiveStep,
		steps.length,
	]);

	const prev = useCallback(() => {
		setActiveStep((prev: number) => {
			if (prev <= 1) return prev;
			return prev - 1;
		});
		if (isFirstStep) return;
		const currentRoute = enableCreateProjectV3
			? createProject
			: createProjectTemplate;

		handlePush(currentRoute(activeStep - 1));
	}, [activeStep, enableCreateProjectV3, isFirstStep, setActiveStep]);

	return (
		<CreateProjectContext.Provider
			value={{
				prev,
				next,
				errors,
				values,
				handleChange,
				formikHandleSubmit,
				step: steps[activeStep],
				isLastStep,
				setFieldError,
				setFieldValue,
				setFieldTouched,
				touched,
				resetForm,
				setValues,
				setTouched,
				setErrors,
				activeStep,
				setActiveStep,
				isADOProvider: providers.current.isAdoProvider,
				isGithubProvider: values.provider === GitHub,
				isByoc: isByoc.current,
				isSingleEnvironmentCreation:
					isByoc.current && !values.createEditingHostForByoc,
			}}
		>
			{children}
		</CreateProjectContext.Provider>
	);
};

function useCreateProject(): CreateProjectContextType {
	const context = useContext(CreateProjectContext);

	if (context === undefined) {
		throw new Error(
			'useCreateProject must be used within a CreateProjectProvider',
		);
	}

	return context;
}

export { useCreateProject, CreateProjectProvider };
